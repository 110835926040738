import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import { env } from "../envconfig";
import ReactPaginate from "react-paginate";
import moment from "moment";

function Notes() {
    const BACKEND_URL = env.BACKEND_URL;
    let { id } = useParams();

    const [isContact, setIsContact] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [verifyAdmin, setverifyAdmin] = useState("");
    const [verifyStatus, setVerifyStatus] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [tasksList, setTasksList] = useState([]);
  
    var limit = 5;
  
    useEffect(() => {
        getNotesTable();
    }, []);
  
    const getNotesTable = async (currentPage) => {
        try {
          let token = localStorage.getItem("authToken");
          setIsLoading(true);
          const response = await axios.get(`${BACKEND_URL}/user/notestable?_page=${currentPage}&_limit=${limit}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // console.log(response.data.details)
          setIsContact(response.data.details.Deta1);
          const total = response.data.details.Deta2;
            setPageCount(Math.ceil(total / 5));
          setIsLoading(false);
        } catch (error) {
          // console.log(error)
          setIsLoading(false);
        }
      };
  
    const history = useHistory();
    const createAssignee = () =>{
      history.push("/notes-form");
    }
  
    const handlePageClick = async (data) => {
      // console.log (data.selected);
      let currentPage = data.selected + 1;
      getNotesTable(currentPage);
    };

    const deleteContact = async (id) => {
      const result = await Swal.fire({
        title: "Do you really want to delete the Note contact ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        try {
          let token = localStorage.getItem("authToken");
          // console.log(id,"==res==")
          setIsLoading(true);
          const response = await axios.post(
            `${BACKEND_URL}/user/deleteNote/${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success("Deleted successfully");
          getNotesTable();
          // let data = response.data.details.map((item) => {
          //   return {
          //     ...item,
          //     isContactShow: false,
          //   };
          // });
          setIsContact(response.data.details);
          setIsLoading(false);
        } catch (error) {
          // console.log(error)
          setIsLoading(false);
        }
      }
    };
  
    
  
    return (
      <div className="contactContainer">
        <div className="createbtncss">
          <Button onClick={createAssignee} class="btn btn-primary">
            Create
          </Button>
        </div>
        <div>
          <table class="table table-fixed ">
            <thead>
              <tr>
              <th>S.NO</th>
                <th>Name</th>
                <th>Title </th>
                <th>Last Date</th>
                <th>Added Date</th>
                <th>status</th>
                <th>Command</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="11">
                    <div className="text-center p-3 my-5">
                      <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {isContact.length > 0 ? (
                    <>
                      {isContact.map((contact,index) => (
                      <tr>
                         <td className="justify-content-center">
                            <p key={index}>
                              <span> {index + 1}</span>
                            </p>
                          </td>
                          <td>{contact.name}</td>
                          <td>{contact.title}</td>
                          <td> {contact.lastdate}</td>
                          <td> {moment(contact.createdAt).format("L")} </td>
                          <td>
                            {contact.tasks.length > 0 ? (
                              <>
                                <div>
                                  <span className="text-danger">pending</span>
                                </div>
                              </>
                            ):(
                              <>
                                <div>
                                  <span className="text-success"> Nil </span>
                                </div>
                              </>
                            )}
                          
                          </td>
                          <td>
                          <div className="d-flex justify-content-center">
                            <Link to={`/addTasks/${contact._id}`}>  
                              <i class="pencss text-primary fa-solid fa-pen"></i>{" "}
                            </Link>
                                <i
                                  class=" dltcss text-danger fa-solid fa-trash"
                                  onClick={() => deleteContact(contact._id)}
                                ></i>
                          </div>
                        </td> 
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan="11">
                          <div className="text-center align-items-center justify-content-center p-3 my-5">
                            {/* there is no product  */}
  
                            <p className="datacss">No Datas Found !</p>
                            <p className="my-3">
                              There is no datas to show you now
                            </p>
                          </div>
                        </td>
                      </tr>  
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
          {isContact.length > 0 ? (
            <div className="paginationcss">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"***"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-md justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          ) : (
            ""
          )}
         
        </div>
      </div>
    );
  }

export default Notes;