import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import { env } from "../envconfig";
import ReactPaginate from "react-paginate";
import moment from "moment";

function ContactTable() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isContact, setIsContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyAdmin, setverifyAdmin] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [pageCount, setPageCount] = useState(0);

  var limit = 5;

  useEffect(() => {
    getDetails();
    //  getAssignees();
  }, []);

  const getDetails = async (currentPage) => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }
      setverifyAdmin(decoded.userType);
      // console.log(token ,"==--token --==")
      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        // console.log(decoded.user_id,"==userid==")
        setIsLoading(true);
        const response = await axios.get(
          `${BACKEND_URL}/user/contactComments?_page=${currentPage}&_limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsContact(response.data.details);
        // console.log(response.data.details,"=-=-=-");
        // console.log(isContact,"-=-=-contact-=-=-;");
        const data = [];
        for (let index = 0; index < isContact.length; index++) {
          const element = isContact[index];
          if (element.comment.length > 0) {
            console.log(element);
            data.push(element);
          }
          setPageCount(Math.ceil(data / 5));
        }
         setIsLoading(false);
        // const total = response.data.details.Deta2;
        // console.log(total,"total")
        // setPageCount(Math.ceil(total / 5));
        // console.log(pageCount,"=-=first-=-=")
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };

  // const data = [];
  // for (let index = 0; index < isContact.length; index++) {
  //   const element = isContact[index];
  //   if (element.comment.length > 0) {
  //     console.log(element);
  //     data.push(element);
  //   }
  //   setPageCount(Math.ceil(data / 5));
  // }
  // const total = isTotal;
  // console.log(total,"total")
  // setPageCount(Math.ceil(total / 5));
  // console.log(pageCount,"=-=second-=-=");

  //   const getAssignees = async () => {
  //     try {
  //       let token = localStorage.getItem("authToken");
  //       setIsLoading(true);
  //       const response = await axios.get(`${BACKEND_URL}/user/getpermission`,{
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setVerifyStatus(response.data.Names.contacts);
  //       // console.log(response.data.Names.contacts,"-=-=-=projectspermission")
  //     } catch (error) {
  //       setIsLoading(false);
  //     }
  //   }

  const handlePageClick = async (data) => {
    // console.log (data.selected);
    let currentPage = data.selected + 1;
    getDetails(currentPage);
  };

  const history = useHistory();

  return (
    <div className="contactContainer">
      <div>
        <table class="table table-fixed ">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Comment</th>
              <th>Contact </th>
              <th>Assignee</th>
              <th>Assignee email</th>
              <th>Edit </th>
              {/* {verifyAdmin == "admin" ? (
                <>
                  <th>Assignees</th>
                  <th>Action</th>
                </>
              ) : (
                <>
                  <th>Action</th>
                </>
              )} */}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="11">
                  <div className="text-center p-3 my-5">
                    <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {isContact.length > 0 ? (
                    isContact.map((contact, index) => (
                        contact.comment.length > 0 ? (
                         contact.comment.map((item) => (
                              <>
                                <tr>
                                  <td className="justify-content-center">
                                    <p key={index}>
                                      <span> {index + 1}</span>
                                    </p>
                                  </td>
                                  <td className="justify-content-center">
                                    {item.comment1}
                                  </td>
                                  <td> {item.name}</td>
                                  <td className="justify-content-center">
                                    {item.commentedBy}
                                  </td>
                                  <td className="justify-content-center">
                                    {item.commentEmail}
                                  </td>
                                  <td>
                                    <div>
                                      <Link to={`/editComments/${contact._id}`}>
                                        <i class="pencss text-primary fa-solid fa-pen"></i>{" "}
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                        ) : null
                        // (
                        //   <>
                        //     <tr>
                        //       <td colSpan="6">
                        //         <div className="text-center align-items-center justify-content-center p-3 my-5">
                        //           {/* there is no product  */}

                        //           <p className="datacss">No Datas Found !</p>
                        //           <p className="my-3">
                        //             There is no datas to show you now
                        //           </p>
                        //         </div>
                        //       </td>
                        //     </tr>
                        //   </>
                        // )
                    ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="6">
                        <div className="text-center align-items-center justify-content-center p-3 my-5">
                          {/* there is no product  */}

                          <p className="datacss">No Datas Found !</p>
                          <p className="my-3">
                            There is no datas to show you now
                          </p>
                        </div>
                      </td>
                    </tr>
                  </>
                )}

                {isContact.length > 0 && isContact.every(contact => contact.comment.length === 0) && (
                    <tr>
                        <td colSpan="6">
                            <div className="text-center align-items-center justify-content-center p-3 my-5">
                                <p className="datacss">No Data Found!</p>
                                <p className="my-3">
                                    There is no data to show you now
                                </p>
                            </div>
                        </td>
                    </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        {isContact.length > 0 ? (
          <div className="paginationcss">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"***"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={
                "pagination pagination-md justify-content-center"
              }
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ContactTable;
