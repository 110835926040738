import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { env } from "../envconfig";
import { useHistory } from "react-router-dom";

function SubAdmin() {
  const BACKEND_URL = env.BACKEND_URL;
  const history = useHistory();

  const [token, setToken, Tokenref] = useStateRef("");
  const [isLoading, setIsLoading] = useState("");

  const [isRoleType, setIsRoleType] = useState("");
  const [roletypeerror, setroletypeerror] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("authToken"));
    if (!Tokenref.current) {
      history.push("/")
    }
    adminDetails();
  }, []);
                  
  const adminDetails = () => {
    const getDetail = axios.get(`${BACKEND_URL}/user/admindetails`, {
      headers: {
        Authorization: `Bearer ${Tokenref.current}`,
      },
    });
  };

  const validateValues = z.object({
    name: z.string().min(1, { message: "Name feild is required !" }),
    email: z
      .string()
      .min(1, { message: "Email feild is required !" })
      .email({ message: "Enter a valid email" }),
    designation: z.string().min(1, { message: "Designation is required !" }),
    projects : z.string(),
    contacts : z.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validateValues),
  });

  const onSubmit = async (data) => {
    // console.log(isRoleType,"isRoleType");
    try {
      if (isRoleType == "") {
        setroletypeerror(true);
      } else {
        setroletypeerror(false);
        console.log(isRoleType,"isRoleType")
        setIsLoading(true);
        var obj = {
          email: data.email,
          fullName: data.name,
          designation: data.designation,
          projects:data.projects,
          contacts:data.contacts,
          roletype: isRoleType,
        };
        console.log(obj,"-=obj-=");
        const response = await axios.post(`${BACKEND_URL}/user/subadmin`, obj, {
          headers: {
            Authorization: `Bearer ${Tokenref.current}`,
          },
        });
        // console.log(response,"send successfully");
        toast.success("The assignee account has been successfully created, and the credentials have been sent to the provided inbox");
        history.push("/admin/assignee-table");
        setIsLoading(false);
        reset();
      }
    } catch (error) {
      // console.log(error.message)
      toast.error("Password sent failed!");
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    reset();
    setIsRoleType("");
  };

  return (
    <div className="container">
      <form className="form">
        <div className="contacthead text-center mb-3">
          <h1>Create Assignee</h1>
        </div>
        <div className="form-control">
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter name"
            {...register("name")}
          />
          {errors?.name && <p className="errorcss">{errors.name?.message}</p>}
        </div>
        <div class="form-control">
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="Enter email"
            {...register("email")}
          />
          {errors?.email && <p className="errorcss">{errors.email?.message}</p>}
        </div>
        <div class="form-control">
          <label>Designation</label>
          <input
            type="text"
            name="designation"
            placeholder="Enter designation"
            {...register("designation")}
          />
          {errors?.designation && (
            <p className="errorcss">{errors.designation?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Projects</label>
          <div className="row mt-1">
            <div className="col-lg-6 px-4 py-1 d-flex ">
              <div>
                <input
                  type="radio"
                  value="yes"
                  name="projects"
                  {...register("projects")}
                />
              </div>
              <div>
                <label className="mx-3">Yes</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-1 d-flex">
              <div>
                <input
                  type="radio"
                  value="no"
                  name="projects"
                  {...register("projects")}
                />
              </div>
              <div>
                <label className="mx-3"> No</label>
              </div>
            </div>
          </div>
          {errors?.projects && (
            <p className="errorcss">Projects is required !</p>
          )}
        </div>
        <div class="form-control">
          <label>Contacts</label>
          <div className="row mt-1">
            <div className="col-lg-6 px-4 py-1 d-flex ">
              <div>
                <input
                  type="radio"
                  value="yes"
                  name="contacts"
                  {...register("contacts")}
                />
              </div>
              <div>
                <label className="mx-3">Yes</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-1 d-flex">
              <div>
                <input
                  type="radio"
                  value="no"
                  name="contacts"
                  {...register("contacts")}
                />
              </div>
              <div>
                <label className="mx-3">No</label>
              </div>
            </div>
          </div>
          {errors?.contacts && (
            <p className="errorcss">Contacts is required !</p>
          )}
        </div>
        <div class="form-control">
          <label>Role type</label>
          {/* <input type="text" name="roletype" placeholder="Enter role type" /> */}
          <select
            name="roletype"
            placeholder="Enter role type"
            onChange={(e) => {
              setIsRoleType(e.target.value);
            }}
          > 
            {isRoleType == "" ? 
            (
            <>
            <option value="" selected disabled>-- Select an option --</option>
            <option> Business Development Executive</option>
            <option> Business Analyst </option>
            <option> Business Head </option>
            <option> CPO</option>
            <option> PC</option>
            <option> Digital Marketing </option>
            <option> Team Lead </option>
            <option> Junior </option>
            </>
            ) : (
             <>
              <option> {isRoleType} </option>
              <option> Business Development Executive</option>
              <option> Business Analyst </option>
              <option> Business Head </option>
              <option> CPO</option>
              <option> PC</option>
              <option> Digital Marketing </option>
              <option> Team Lead </option>
              <option> Junior </option>
             </>

            ) }
           
          </select>
          {roletypeerror == true ? (
            <p className="errorcss">Roletype is required</p>
          ) : (
            ""
          )}
        </div>
        {!isLoading && (
          <>
            <div className="row justify-content-center mb-3">
              <div className="col-lg-3 my-2 mx-3">
                <Button onClick={onCancel}>Cancel</Button>
              </div>
              <div className="col-lg-3 my-2 mx-3">
                <Button type="submit" onClick={handleSubmit(onSubmit)}>
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default SubAdmin;
