import {  Link} from "react-router-dom"
import { jwtDecode } from "jwt-decode";



import { Navigate } from "react-router-dom";

const RequireAuth = ({ Component, userTypes }) => {
 
// const [isAuthenticated, setIsAuthenticated] = useState(false);

// console.log("heheheheh")

    let decoded = jwtDecode(localStorage.getItem("authToken"))

 // Your authentication logic goes here...


    // console.log({ userTypes })

    let isAuthenticated = userTypes.includes(decoded.userType)


   
    
        // return (<div>helllllooo</div>)
 
  return isAuthenticated ? <Component /> : <Link to="/signin" />;
};
export default RequireAuth;