import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { env } from "../../../../envconfig";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";
import ReactPaginate from "react-paginate";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const BACKEND_URL = env.BACKEND_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [verifyAdmin, setverifyAdmin] = useState("");
  const [isLoginDetails, setIsLoginDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  var limit = 5;

  useEffect(async () => {
    await getDetails();
  }, []);

  const getDetails = async (currentPage) => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }
      setverifyAdmin(decoded.userType);
      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        setIsLoading(true);
        const response = await axios.get(
          `${BACKEND_URL}/user/getlogindetails?_page=${currentPage}&_limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsLoginDetails(response.data.details.Deta1);
        // console.log(isLoginDetails.length);
        const total = response.data.details.Deta2;
        setPageCount(Math.ceil(total / 5));
        // console.log(total,"/=-=-=-=");
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };

  const handlePageClick = async (data) => {
    // console.log (data.selected);
    let currentPage = data.selected + 1;
    getDetails(currentPage);
  };

  return (
    <>
      {verifyAdmin == "admin" ? (
        <>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <Flex px="25px" justify="space-between" mb="10px" align="center">
              <Text
                color={textColor}
                fontSize="22px"
                marginBottom="20px"
                marginTop="10px"
                fontWeight="700"
                lineHeight="100%"
              >
                Loggedin Details
              </Text>
            </Flex>

            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Role</Th>
                  <Th>Device</Th>
                  <Th>OS</Th>
                  <Th>IP</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isLoading ? (
                  <Tr>
                    <Td colSpan="11">
                      <div className="text-center p-3 my-5">
                        <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                      </div>
                    </Td>
                  </Tr>
                ) : (
                  <>
                    {isLoginDetails && isLoginDetails.length > 0 ? (
                      <>
                        {isLoginDetails.map((details) => {
                          return (
                            <Tr>
                              <Td>{details.name}</Td>
                              <Td>{details.useremail}</Td>
                              <Td>{details.roletype}</Td>
                              <Td>{details.platform}</Td>
                              <Td>{details.OS}</Td>
                              <Td>{details.ipAddress}</Td>
                              <Td>
                                {moment(details.createdDate).format("lll")}
                              </Td>
                            </Tr>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="11">
                            <div className="text-center align-items-center justify-content-center p-3 my-5">
                              <p className="datacss">No Datas Found !</p>
                              <p className="my-3">
                                There is no datas to show you now
                              </p>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </Tbody>
            </Table>

            <div className="paginationcss">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"***"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-md justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </Card>
        </>
      ) : (
        ""
      )}
    </>
  );
}
