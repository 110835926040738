import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, useParams } from "react-router-dom";
import { env } from "../../../envconfig";

function EditContact() {
  const BACKEND_URL = env.BACKEND_URL;

  let { id } = useParams();
  const [product, setProduct] = useState({
    name: "",
    contact: "",
    channel: "",
    country: "",
    date: "",
    category: "",
    subcategory: "",
    status: "",
    description: "",
    assignee:[],
  });

  const [isLoading, setIsLoading] = useState("");

  const history = useHistory();

  const [isSelectItemFull, setIsSelectItemFull] = useState("");

  const [isNames, setName] = useState([]);

  useEffect(() => {
    getdetails();
    getContact();
  }, []);

  const getContact = async () => {
    try {
      let token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/user/getEditcontact/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProduct({
        name: response.data.details.name,
        contact: response.data.details.contact,
        channel: response.data.details.channel,
        country: response.data.details.country,
        date: response.data.details.date,
        category: response.data.details.category,
        subcategory: response.data.details.subcategory,
        status: response.data.details.status,
        description: response.data.details.description,
        assignee: response.data.details.assignee,
      });
      setSelectItem(response.data.details.assignee);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong try again");
    }
  };

  const getdetails = () => {
    let token = localStorage.getItem("authToken");
    axios
      .get(`${BACKEND_URL}/user/assignee`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log({ res : response?.data})
        let data = response?.data.Names.map((name) => {
          return { fullName: name.fullName };
        });

        console.log({ d: data});
        setName(data);
      })
      .catch((error) => {
        // console.log(error);
        setIsLoading(false);
      });
  };

  const onSubmit = async () => {
    try {
      if (
        product.name === "" ||
        product.contact === "" ||
        product.channel === "" ||
        product.country === "" ||
        product.date === "" ||
        // product.status === "" ||
        product.description === ""
      ) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        toast.error("Fill out all the feilds");
      } else {
        if (selectItem == "") {
          setIsSelectItemFull(true);
        } else {
          let token = localStorage.getItem("authToken");
          // console.log("inside clicked",product)
          setIsLoading(true);
          setIsSelectItemFull(false);
          product["assignee"] = selectItem;
          console.log(product,"-=-product-=-")
          axios
            .put(`${BACKEND_URL}/user/updateContactAssignee/${id}`, product, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              toast.success("Details updated successfully");
              history.push("/admin/contact-table");
            })
            .catch((error) => {
              // console.log(error);
              toast.error("Detail does not updated");
            });
        }
      }
    } catch (error) {
      // console.log(error)
      toast.error("Details does not got");
      setIsLoading(false);
    }
    //}
  };

  const [selectItem, setSelectItem] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectItem(selectedList);
    // console.log({selectedList, selectedItem}, "value")
  };

  const onDelete = (selectedList, removedItem) => {
    setSelectItem(selectedList);
    // console.log({selectedList, removedItem},"removed value")
  };

  const cancelFn = () => {
    history.push("/admin/contact-table");
  };

  return (
    <div className="contactformcontainer">
      <form className="form">
        <div className="cancelcss">
          <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
        </div>
        <div className="contacthead text-center mb-3">
          <h1>Edit contact</h1>
        </div>
        <div className="form-control">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
            placeholder="Enter name"
          />
        </div>
        <div class="form-control">
          <label>Contact</label>
          <input
            type="text"
            name="contact"
            value={product.contact}
            onChange={(e) =>
              setProduct({ ...product, contact: e.target.value })
            }
            placeholder="Enter contact"
          />
        </div>
        <div class="form-control">
          <label>Channel</label>
          <input
            type="text"
            name="channel"
            value={product.channel}
            onChange={(e) =>
              setProduct({ ...product, channel: e.target.value })
            }
            placeholder="Enter Channel"
          />
        </div>
        <div class="form-control">
          <label>Country</label>
          <input
            type="text"
            value={product.country}
            onChange={(e) =>
              setProduct({ ...product, country: e.target.value })
            }
            name="country"
            placeholder="Enter country"
          />
        </div>
        <div class="form-control">
          <label>Date</label>
          <input
            type="date"
            name="date"
            value={product.date}
            onChange={(e) => setProduct({ ...product, date: e.target.value })}
            placeholder="Enter date"
          />
        </div>
        <div class="form-control">
          <label>Category</label>
          <input
            type="text"
            name="category"
            value={product.category}
            onChange={(e) =>
              setProduct({ ...product, category: e.target.value })
            }
            placeholder="Enter Category"
          />
        </div>
        <div class="form-control">
          <label>SubCategory</label>
          <input
            type="text"
            name="subcategory"
            value={product.subcategory}
            onChange={(e) =>
              setProduct({ ...product, subcategory: e.target.value })
            }
            placeholder="Enter SubCategory"
          />
        </div>
        <div class="form-control">
          <label>Assignee </label>
          <Multiselect
            options={isNames}
            displayValue="fullName"
            className="mt-3"
            name="assignee"
            onSelect={onSelect}
            onRemove={onDelete}
            selectedValues={product.assignee}
          />
          {isSelectItemFull == true ? (
            <p className="errorcss">
              <span> Assignee is required !</span>
            </p>
          ) : (
            ""
          )}
        </div>
        <div class="form-control">
          <label>Status</label>
          <select
            name="status"
            value={product.status}
            placeholder="Enter status"
            // {...register("status")}
            onChange={(e) => setProduct({ ...product, status: e.target.value })}
          >
            <option> New </option>
            <option> Ongoing </option>
            <option> Converted</option>
            <option> Rejected</option>
          </select>
          {/* <input
            type="text"
            name="status"
            value={product.status}
            onChange={(e) => setProduct({ ...product, status: e.target.value })}
            placeholder="Enter status"
          /> */}
        </div>
        <div class="form-control">
          <label>Description</label>
          <input
            type="text"
            name="description"
            value={product.description}
            onChange={(e) =>
              setProduct({ ...product, description: e.target.value })
            }
            placeholder="Enter description"
          />
        </div>

        {!isLoading && (
          <Button type="submit" onClick={onSubmit}>
            Update
          </Button>
        )}
      </form>
    </div>
  );
}

export default EditContact;
