import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import { env } from "../envconfig";
import ReactPaginate from "react-paginate";
import { IoTrophyOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';


function ContactTable() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isContact, setIsContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyAdmin, setverifyAdmin] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [counts,setCounts] = useState("");
  const [search, setsearch, searchref] = useStateRef("");
  const [searchEmpt,setSearchEmpt,searchEmptref] = useStateRef(false);
  const initialFormValue = {
    fromdate: "",
    todate: "",
  };
  const [formValue, setFormValue, setFormRef] = useStateRef(initialFormValue);
  const { fromdate, todate } = formValue;

  var limit = 5;

  useEffect(() => {
     getDetails();
     getAssignees();
  }, []);

  const getDetails = async (currentPage) => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }
      setverifyAdmin(decoded.userType);

      // console.log(token ,"==--token --==")
      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        // console.log(decoded.user_id,"==userid==")
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/contacts?_page=${currentPage}&_limit=${limit}`,setFormRef.current,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsContact(response.data.details.Deta1);
        setCounts(response.data.details.Deta2);
        const total = response.data.details.Deta2;
        // console.log(counts,"counts--0-");
        setPageCount(Math.ceil(total / 5));
        // console.log(pageCount,"=-=-=-=")
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };

  const getAssignees = async () => {
    try {
      let token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.get(`${BACKEND_URL}/user/getpermission`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVerifyStatus(response.data.Names.contacts);
      // console.log(response.data.Names.contacts,"-=-=-=projectspermission")
    } catch (error) {
      setIsLoading(false);
    }
  }

  // const [selectedDate, setSelectedDate] = useState("");

  const handleChange = (e) =>{
    // const selectedDate = event.target.value;
    // const filteredResult = isContact.filter(item => item.date > selectedDate );
    // setIsContact(filteredResult);
    // setCounts(filteredResult.length);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    getDetails();
  }

  const handlePageClick = async (data) => {
    // console.log (data.selected);
    let currentPage = data.selected + 1;
    getDetails(currentPage);
  };

  const history = useHistory();

  const createContact = () => {
    history.push("/contact-form");
  };

  const deleteContact = async (id) => {
    const result = await Swal.fire({
      title: "Do you really want to delete the Contact ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        let token = localStorage.getItem("authToken");
        // console.log(id,"==res==")
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/deleteContact/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Deleted successfully");
        getDetails();
        let data = response.data.details.map((item) => {
          return {
            ...item,
            isContactShow: false,
          };
        });
        setIsContact(data);
        setIsLoading(false);
      } catch (error) {
        // console.log(error)
        setIsLoading(false);
      }
    }
  };
  const viewdata = async (id) => {
    // console.log(id,"id");
    const result = await Swal.fire({
      title: "More Details",
      // className:"swalCss",
      width: '700px',
      html: `
        <table  class="table table-fixed" border="1">
        
          <tbody>
            <tr>
              <td>Name :</td>
              <td>${id.name}</td>
            </tr>
          <tr>
          <td>Channel :</td>
          <td>${id.channel}</td>
          </tr>
          <tr>
          <td>Country :</td>
          <td>${id.country}</td>
          </tr>
          <tr>
          <td>Contact :</td>
          <td>${id.contact}</td>
          </tr>
          <tr>
          <td>Date :</td>
          <td>${id.date}</td>
          </tr>
          <tr>
          <td>Category :</td>
          <td>${id.category}</td>
          </tr>
          <tr>
          <td>SubCategory :</td>
          <td>${id.subcategory}</td>
          </tr>
          <tr>
          <td>status :</td>
          <td>${id.status}</td>
          </tr>
          <tr>
          <td>Description :</td>
          <td>${id.description}</td>
          </tr>
          </tbody>
        </table>
      `,
      confirmButtonText: "Close",
    });
  };


  const downloadData = async () => {
    const result = await Swal.fire({
      title: "Enter Your Password",
      input: 'text',
      inputPlaceholder: 'Enter your name here',
      showCancelButton: true,
      confirmButtonText: 'Download',
      cancelButtonText: 'Cancel',
    });
    if(result.isConfirmed){
      const name = result.value;
      try {
        var obj ={
          Name : name
        }
        let token = localStorage.getItem("authToken");  
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/getExcelpermission`, obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if(response.data.status == true){
          const fieldsToExport = ['name', 'contact', 'country','status','category','channel','date','altercontact','subcategory','description','comment'];
          const filteredData = isContact.map(item => {
            const filteredItem = {};
            fieldsToExport.forEach(field => {
              filteredItem[field] = item[field];
            });
              return filteredItem;
          })
          const worksheet = XLSX.utils.json_to_sheet(filteredData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'My_File.xlsx');

          // const csvData = new Blob([Papa.unparse(isContact)], { type: 'text/csv;charset=utf-8' });
          // saveAs(csvData, 'myFile.csv');

          getDetails();
          toast.success("File is Downloading");
        }else{
          getDetails();
          toast.error("Password is incorrect , Please enter a correct password");
        }
      } catch (error) {
        setIsLoading(false);
      }
    } 
  }


  const [contactType, setContactType] = useState(true);

  const togglePassword = () => {
    if (contactType === true) {
      setContactType(false);
      return;
    }
    setContactType(true);
  };

  const handleChangeInput = (e) => {
    try {
      console.log(e.target.value, "eeeeeeeeeeeeeeeeeeeeeeeee");
      setsearch(e.target.value);
      searchWalletList();
    } catch (error) { }
  };

  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = isContact.filter((data) =>
        regexPattern.test(data.name)
      );
      if (searchWallet.length > 0) {
        setIsContact(searchWallet);
        // setDataHide(false);
        // settotal(1);
      } else {
        console.log("Its come in else part -----");
        setIsContact(searchWallet);
        // getUserbalance(1);
      }
    } else {
      console.log("Its come in second part -----");
      getDetails();
      // getUserbalance(1);
    }
  };

  const getAllContacts = async() => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }

      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        // console.log(decoded.user_id,"==userid==")
        setIsLoading(true);
        const response = await axios.get(
          `${BACKEND_URL}/user/getAllContacts`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsContact(response.data.details.Deta1);
        setCounts(response.data.details.Deta2);
        setFormValue(initialFormValue);
        setSearchEmpt(true);
        
        // console.log(counts,"counts--0-");
        // setPageCount(Math.ceil(total / 5));
        // console.log(pageCount,"=-=-=-=")
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };

  const handlePriorityClick = async (id) => {
    const result = await Swal.fire({
      title: 'Set Priority',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'High',
      denyButtonText: 'Medium',
      cancelButtonText: 'Rejected',
      customClass: {
        confirmButton: 'swal2-confirm',
        denyButton: 'swal2-deny',
        cancelButton: 'swal2-cancel'
      }
    });

    if(result.dismiss === "backdrop"){
      return;
    }
    if(result.isConfirmed || result.isDenied || result.isDismissed){
      try {
      let newPriority = 'None';
      if (result.isConfirmed) {
        newPriority = 'High';
      } else if (result.isDenied) {
        newPriority = 'Medium';
      } else if (result.isDismissed) {
        newPriority = 'Rejected';
      }
      console.log(newPriority,"=-=-newPriority down =-=-");

      
        let token = localStorage.getItem("authToken");
        // console.log(id,"==res==")
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/addpriority/${id}`,
          { priority: newPriority },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsLoading(false);
        if (response.data.status == true) {
          // Update local state to reflect the change
          // setData((prevData) =>
          //   prevData.map((item) =>
          //     item.id === id ? { ...item, priority: newPriority } : item
          //   )
          // );
          toast.success(response.data.message);
          getDetails();
          console.error('Updated priority:', response.data.message);
        } else {
          // toast.error(response.data.message);
          console.error('Failed to update priority:', response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating priority:', error);
      }
    }

  };



  return (
    <div className="contactContainer">
      <div className="createbtncss_contact">
      {verifyAdmin == "admin" ? (
        <>
          <div className="countsright">
          <div className="input_border">
                          <div className="mt-1" onClick={() => searchWalletList()}>
                          <i class="fa-solid fa-magnifying-glass fa-xs"></i>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Search by name"
                              name="searchvallist"
                              onChange={handleChangeInput}
                              className="contact_search"
                            />
                          </div>
                        </div>
            </div>
          <div className="countsright">
            <span>From : </span>
            <Form.Control
                type="date"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="filterdatacss"
                value={fromdate}
                onChange={handleChange}
                name="fromdate"
              />
            </div>
            <div className="countsright">
            <span>To : </span>
            <Form.Control
                type="date"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="filterdatacss"
                value={todate}
                onChange={handleChange}
                name="todate"
              />
            </div>
          <div className="countsright">
            <span>Counts : </span>
            <Button class="btn btn-outline-primary " >{counts}</Button>
          </div>
        
              <div className="countsright mb-0 ml-1">
                <i class="fa-solid fa-download fa-lg" onClick={downloadData}></i>
              </div>
              </>
      ) : (
        ""
      )}
       <div className="countsright123">
            {verifyStatus == "yes" ?(
              <Button onClick={createContact} class="btn btn-primary">
                Create
              </Button>
            ):(
                ""
            )}
          </div>
          <div className="countsright123">
              <Button onClick={getAllContacts} class="btn btn-primary">
                All
              </Button>
          </div>
          </div>
      <div>
        <table class="table table-fixed ">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Contact</th> */}
              {/* <th>Channel</th>
              <th>Country</th>*/}
              <th>Date</th> 
              <th>Category</th>
              {/* <th>SubCategory</th> */}
              {/* <th>status</th> */}
              <th>Status</th>
              {verifyAdmin == "admin" ? (
                <>
                  <th>Assignees</th>
                  <th>Action</th>
                </>
              ) : (
                <>
                  <th>Action</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="11">
                  <div className="text-center p-3 my-5">
                    <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {isContact.length > 0 ? (
                  <>
                    {isContact.map((contact) => (
                      <tr>
                        <td className="justify-content-center">
                          {contact.name}
                        </td>
                        {/* <td>
                          <div className="d-grid gap-2 d-flex justify-content-center">
                            <span>
                              {" "}
                              {contact.isContactShow
                                ? contact.contact
                                : "******"}{" "}
                            </span>
                            <span
                              onClick={() => {
                                var data = isContact.map((item) => {
                                  if (item._id === contact._id) {
                                    if (contactType == false) {
                                      return {
                                        ...item,
                                        isContactShow: true,
                                      };
                                    } else {
                                      return {
                                        ...item,
                                        isContactShow: false,
                                      };
                                    }
                                  } else {
                                    return {
                                      ...item,
                                      isContactShow: false,
                                    };
                                  }
                                });
                                // console.log(data)
                                setIsContact(data);
                                togglePassword();
                              }}
                            >
                              {contact.isContactShow === true ? (
                                <i class="fa-regular fa-eye-slash eyecss"></i>
                              ) : (
                                <i class="fa-regular fa-eye eyecss"></i>
                              )}
                            </span>
                          </div>
                        </td> */}
                        {/* <td>{contact.channel}</td>
                        <td>{contact.country}</td> */}
                        <td>{contact.date}</td>
                        <td>{contact.category}</td>
                        {/* <td>{contact.subcategory}</td> */}
                        {/* <td>{contact.status}</td> */}
                        <td>{contact.status}</td>
                        {verifyAdmin == "admin" ? (
                          <>
                            <td>
                              {contact.assignee.map((name) => (
                                <ul className="licss">
                                  <li>{name.fullName}</li>
                                </ul>
                              ))}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <Link to={`/edit/${contact._id}`}>
                                  <i class=" pencss text-primary fa-solid fa-pen"></i>{" "}
                                </Link>
                                <i
                                  class=" dltcss text-danger fa-solid fa-trash"
                                  onClick={() => deleteContact(contact._id)}
                                ></i>

                                <i
                                  class="fa-regular fa-eye dltcss text-dark"
                                  onClick={() => viewdata(contact)}
                                ></i>

                                <i
                                 class=" infocss fa-solid fa-info"
                                 onClick={() => handlePriorityClick(contact._id)}
                                 ></i>

                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <div>
                                <Link to={`/editByAssignee/${contact._id}`}>
                                  <i class=" pencss text-primary fa-solid fa-pen"></i>{" "}
                                </Link>
                                <i
                                  class="fa-regular fa-eye dltcss text-dark"
                                  onClick={() => viewdata(contact)}
                                ></i>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="11">
                        <div className="text-center align-items-center justify-content-center p-3 my-5">
                          {/* there is no product  */}

                          <p className="datacss">No Datas Found !</p>
                          <p className="my-3">
                            There is no datas to show you now
                          </p>
                          {/* {verifyAdmin == "admin" ? (
                            <div>
                              <Button
                                onClick={createContact}
                                class="btn btn-primary"
                              >
                                Create
                              </Button>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        {isContact.length > 0 && searchEmptref.current == false ? (
          <div className="paginationcss">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"***"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination pagination-md justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          ""
        )}
       
      </div>
    </div>
  );
}

export default ContactTable;
