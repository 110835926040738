import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { env } from "../../../envconfig";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { jwtDecode } from "jwt-decode";


function SignIn() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isLoading, setIsLoading] = useState("");

  const history = useHistory();


  useEffect(() => {
    let token = localStorage.getItem("authToken");
    if (token) {
      window.location.href = "/admin/default";
        }
  }, []);

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const validateValues = z.object({
    email: z.string().min(1, { message: "Email feild is required" }),
    password: z.string().min(1, "Password feild is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validateValues),
  });

  const getAuthToken = () => {
    if (localStorage.getItem("authToken")) {
      return localStorage.getItem("authToken");
    } else {
      return "";
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BACKEND_URL}/user/login`, {
        email: data.email,
        password: data.password,
      });
      // console.log(`login ${response} successfully`, response)
      // console.log(response?.data?.token,"==Authorization==")

      if (response?.data?.status === true) {
        localStorage.setItem("authToken", response?.data?.token);
        axios.defaults.headers.common["Authorization"] = getAuthToken();
        toast.success("Logged in successfully!");
        let decoded;
        let token = localStorage.getItem("authToken");
        if (token) {
          decoded = jwtDecode(token);
        }
        if(decoded.userType == "admin"){
          history.push("/admin/default");
        }else {
          history.push("/admin/contact-table");
        };
      } else {
        toast.error("Incorrect email or password !");
      }
      setIsLoading(false);
    } catch (error) {
      // console.log(error.message)
      toast.error("Something went wrong try again later!");
      setIsLoading(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>

        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Email<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            name="email"
            {...register("email")}
            placeholder="mail@simmmple.com"
            fontWeight="500"
            size="lg"
          />
          {errors?.email && <p className="errorcss">{errors.email?.message}</p>}
          <FormLabel
            ms="4px"
            mt="24px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            display="flex"
          >
            Password<Text color={brandStars}>*</Text>
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              fontSize="sm"
              placeholder="Min. 8 characters"
              name="password"
              {...register("password")}
              size="lg"
              type={show ? "text" : "password"}
              variant="auth"
            />
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                color={textColorSecondary}
                _hover={{ cursor: "pointer" }}
                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
          {errors?.password && (
            <p className="errorcss">{errors.password?.message}</p>
          )}
          {!isLoading && (
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mt="35px"
              mb="24px"
              onClick={handleSubmit(onSubmit)}
            >
              Sign In
            </Button>
          )}
        </FormControl>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
