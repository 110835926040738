/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState,useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { LinkIcon,StarIcon,ChatIcon,CheckIcon,RepeatClockIcon,CloseIcon,ArrowForwardIcon } from '@chakra-ui/icons';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { jwtDecode } from "jwt-decode";
import { env } from "../../../envconfig";


export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const BACKEND_URL = env.BACKEND_URL;

  const [isContact, setIsContact] = useState([]);
  const [isAssignee, setIsAssignee] = useState([]);
  const [isProject, setIsProject] = useState([]);
  const [isOngoing, setIsOngoing] = useState([]);
  const [isConverted, setIsConverted] = useState([]);
  const [isRejected, setIsRejected] = useState([]);
  // const [isLogin, setIsLogin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyAdmin, setverifyAdmin] = useState("");

  useEffect(async () => {
    await getDetails();
  }, []);

  const getDetails = async () => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }
      setverifyAdmin(decoded.userType);

      // console.log(token ,"==--token --==")
      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        // console.log(decoded.user_id,"==userid==")
        setIsLoading(true);
        const response = await axios.get(
          `${BACKEND_URL}/user/getCounts`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.details,"-=-=response.data.details=-=-");
        setIsContact(response.data.details.Deta1);
        setIsAssignee(response.data.details.Deta2);
        setIsProject(response.data.details.Deta3);
        setIsOngoing(response.data.details.Deta4);
        setIsConverted(response.data.details.Deta5);
        setIsRejected(response.data.details.Deta6);
        // setIsLogin(response.data.details.Deta7);
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };



  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {isLoading ? (
         <div className="text-center p-3 my-5">
         <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
       </div>
      ):(
        <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>

  {verifyAdmin == "admin" ? (
  <>
      <>
          {isContact.length > 0 ? (
           <>
           <div className="d-flex">
               <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={LinkIcon} color={brandColor} />
                     }
                   />
                 }
                 
                 name='Lead'
                 value={isContact.length}
                 endContent={
                  <Link to="/admin/contact-table" >
                  <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='25px' h='25px' as={ArrowForwardIcon} color={brandColor} />
                  }
                />
                </Link>
                 }
               />
              
               </div>
           </>

          ):(
            <>
            
             <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={LinkIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Lead'
                 value='$ 0'
                 endContent={
                  <Link to="/admin/contact-table" >
                  <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='25px' h='25px' as={ArrowForwardIcon} color={brandColor} />
                  }
                />
                </Link>
                 }
               />
               
              </>
          )}
          </>
  </>
):(
  <>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='25px' h='25px' as={LinkIcon} color={brandColor} />
              }
            />
          }
          name='Earnings'
          value='$350.4'
        />
  </>
)}

{verifyAdmin == "admin" ? (
  <>
          <>
          {isAssignee.length > 0 ? (
           <>
               <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={StarIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Assignees'
                 value={isAssignee.length}
                 endContent={
                  <Link to="/admin/assignee-table" >
                  <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='25px' h='25px' as={ArrowForwardIcon} color={brandColor} />
                  }
                />
                </Link>
                 }
               />
           </>

          ):(
            <>
             <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={StarIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Assignees'
                 value='$ 0'
                 endContent={
                  <Link to="/admin/assignee-table" >
                  <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='25px' h='25px' as={ArrowForwardIcon} color={brandColor} />
                  }
                />
                </Link>
                 }
               />
              </>
          )}
          </>
  </>
):(
  <>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='25px' h='25px' as={StarIcon} color={brandColor} />
              }
            />
          }
          name='Spend this month'
          value='$642.39'
        />
  </>
)}

{verifyAdmin == "admin" ? (
  <>
           <>
          {isProject.length > 0 ? (
           <>
               <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={ChatIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Projects'
                 value={isProject.length}
                 endContent={
                  <Link to="/admin/project-table" >
                  <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='25px' h='25px' as={ArrowForwardIcon} color={brandColor} />
                  }
                />
                </Link>
                 }
               />
           </>

          ):(
            <>
             <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={ChatIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Projects'
                 value='$ 0'
                 endContent={
                  <Link to="/admin/project-table" >
                  <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='25px' h='25px' as={ArrowForwardIcon} color={brandColor} />
                  }
                />
                </Link>
                 }
               />
              </>
          )}
          </>
  </>
):(
  <>
        <MiniStatistics 
              icon={
                <Icon w='25px' h='25px' as={ChatIcon} color={brandColor} />
              }         
        growth='+23%' name='Sales' value='$574.34' />
  </>
)}

{verifyAdmin == "admin" ? (
  <>
          <>
          {isOngoing.length > 0 ? (
           <>
               <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={CheckIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Ongoing status'
                 value={isOngoing.length}
               />
           </>

          ):(
            <>
             <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={CheckIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Ongoing status'
                 value=' 0'
               />
              </>
          )}
          </>
  </>
):(
  <>
         <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        />
  </>
)}

{verifyAdmin == "admin" ? (
  <>
         <>
          {isConverted.length > 0 ? (
           <>
               <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={RepeatClockIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Converted status'
                 value={isConverted.length}
               />
           </>

          ):(
            <>
             <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='25px' h='25px' as={RepeatClockIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Converted status'
                 value=' 0'
               />
              </>
          )}
          </>
  </>
):(
  <>
         <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='25px' h='25px' as={RepeatClockIcon} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        />
  </>
)}

{verifyAdmin == "admin" ? (
  <>
          <>
          {isRejected.length > 0 ? (
           <>
               <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='20px' h='20px' as={CloseIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Rejection status'
                 value={isRejected.length}
               />
           </>

          ):(
            <>
             <MiniStatistics
                 startContent={
                   <IconBox
                     w='56px'
                     h='56px'
                     bg={boxBg}
                     icon={
                       <Icon w='20px' h='20px' as={CloseIcon} color={brandColor} />
                     }
                   />
                 }
                 name='Rejection status'
                 value=' 0'
               />
              </>
          )}
          </>
  </>
):(
  <>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='20px' h='20px' as={CloseIcon} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        />
  </>
)}

      </SimpleGrid>
      )}


      <SimpleGrid >
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

      </SimpleGrid>
    </Box>
  );
}
