import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, useParams } from "react-router-dom";
import { env } from "../../../envconfig";

function EditByAssignee() {
  const BACKEND_URL = env.BACKEND_URL;

  let { id } = useParams();
  const [product, setProduct] = useState({
    comment: "",
    status: "",
    projectname:"",
  });

  const [isComment, setIsComment] = useState(false);
  const [isStatus, setIsStatus] = useState(false);

  const [isLoading, setIsLoading] = useState("");

  const history = useHistory();

  useEffect(() => {
    getContact();
  }, []);

  const getContact = async () => {
    try {
      let token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/user/getEditcontact/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProduct({
        
        status: response?.data?.details?.status,
        projectname: response?.data?.details?.name,
        comment: response?.data?.details?.comment[0]?.comment1 ?? "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // toast.error("Something went wrong try again");
    }
  };

  // useEffect(() => {
  //   console.log(product,"=product")
  // },[product])

  const onSubmit = async () => {
    console.log(product,"product")
    try {
      if (product.comment == "" || product.status == "") {
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsComment(true);
        setIsStatus(true);
        toast.error("Fill out the field");
      } else {
        setIsComment(false);
        setIsStatus(false);
        let token = localStorage.getItem("authToken");
        // console.log("inside clicked",product)
        setIsLoading(true);
        axios
          .put(`${BACKEND_URL}/user/updateContact/${id}`, product, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            // console.log(response,"-=-=-=-=-=--=response")
            toast.success("Details updated successfully");
            history.push("/admin/contact-table");
          })
          .catch((error) => {
            // console.log(error);
            setIsLoading(false);
          });
      }
    } catch (error) {
      // console.log(error)
      toast.error("Details does not got");
      setIsLoading(false);
    }
    //}
  };

  const cancelFn = () => {
    history.push("/admin/contact-table");
  };

  return (
    <div className="contactformcontainer">
      <form className="form">
        <div className="cancelcss">
          <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
        </div>
        <div className="contacthead text-center mb-3">
          <h1>Edit contact</h1>
        </div>

        <div class="form-control">
          <label> Comment </label>
          <input
            type="text"s
            name="comment"
            value={product.comment}
            onChange={(e) =>
              setProduct({ ...product, comment: e.target.value })
            }
            placeholder="Enter Comment"
          />
            {isComment == true ? (
              <p className="errorcss"> Comment is required !</p>
            ):("")}
        </div>
        <div class="form-control">
          <label>Status</label>
          <select
            name="status"
            value={product.status}
            placeholder="Enter status"
            onChange={(e) => setProduct({ ...product, status:e.target.value })}
          >
            <option> New </option>
            <option> Ongoing </option>
            <option> Converted</option>
            <option> Rejected</option>
          </select>
          {isStatus == true ? (
              <p className="errorcss"> Status is required !</p>
            ):("")}
        </div>

        {!isLoading && (
          <Button type="submit" onClick={onSubmit}>
            Update
          </Button>
        )}
      </form>
    </div>
  );
}

export default EditByAssignee;
