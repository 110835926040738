import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, useParams } from "react-router-dom";
import { env } from "../../../envconfig";

function EditProject() {
  const BACKEND_URL = env.BACKEND_URL;

  let { id } = useParams();
  const [product, setProduct] = useState({
    projectname: "",
    clientname: "",
    managername: "",
    team: "",
    projectcategory: [],
    status: "",
    modules: "",
    milestone: "",
  });

  const [isLoading, setIsLoading] = useState("");

  const history = useHistory();

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    try {
      let token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/user/getEditProject/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.details,"-=-yeah=-=-");
      setProduct({
        projectname: response.data.details.projectname,
        clientname: response.data.details.clientname,
        managername: response.data.details.managername,
        team: response.data.details.team,
        projectcategory: response.data.details.projectcategory,
        status: response.data.details.status,
        modules: response.data.details.modules,
        milestone: response.data.details.milestone,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong try again");
    }
  };

  const onSubmit = async () => {
    try {
      if (
        product.projectname === "" ||
        product.clientname === "" ||
        product.managername === "" ||
        product.team === "" ||
        product.projectcategory === "" ||
        product.status === "" ||
        product.modules === "" ||
        product.milestone === ""
      ) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        toast.error("Fill out the all feilds");
      } else {
        let token = localStorage.getItem("authToken");
        // console.log("inside clicked",product)
        setIsLoading(true);
        axios
          .put(`${BACKEND_URL}/user/updateProject/${id}`, product, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            toast.success("Details updated successfully");
            history.push("/admin/project-table");
            console.log(product.projectcategory, "==-=-=-");
          })
          .catch((error) => {
            // console.log(error);
            toast.error("Details not updated");
          });
      }
    } catch (error) {
      // console.log(error)
      toast.error("Details does not got");
      setIsLoading(false);
    }
    //}
  };

  const cancelFn = () => {
    history.push("/admin/project-table");
  };

  return (
    <div className="contactformcontainer">
      <form className="form">
        <div className="cancelcss">
          <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
        </div>
        <div className="contacthead text-center mb-3">
          <h1>Edit Project</h1>
        </div>
        <div className="form-control">
          <label>Project Name</label>
          <input
            type="text"
            name="projectname"
            value={product.projectname}
            onChange={(e) =>
              setProduct({ ...product, projectname: e.target.value })
            }
            placeholder="Enter project name"
          />
        </div>
        <div class="form-control">
          <label>Client Name</label>
          <input
            type="text"
            name="clientname"
            value={product.clientname}
            onChange={(e) =>
              setProduct({ ...product, clientname: e.target.value })
            }
            placeholder="Enter client name"
          />
        </div>
        <div class="form-control">
          <label>Manager Name</label>
          <input
            type="text"
            name="managername"
            value={product.managername}
            onChange={(e) =>
              setProduct({ ...product, managername: e.target.value })
            }
            placeholder="Enter manager name"
          />
        </div>
        <div class="form-control">
          <label>Team</label>
          <input
            type="text"
            value={product.team}
            onChange={(e) => setProduct({ ...product, team: e.target.value })}
            name="team"
            placeholder="Enter team"
          />
        </div>
        <div class="form-control">
          <label>Project Category</label>
          <div className="row mt-3">
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  value="website"
                  name="projectcategory"
                  checked={product.projectcategory.includes('website')}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      setProduct({
                        ...product,
                        projectcategory: [...product.projectcategory, e.target.value],
                      });
                    } else {
                      setProduct({
                        ...product,
                        projectcategory: product.projectcategory.filter(
                          (category) => category !== e.target.value
                        ),
                      });
                    }
                  }}
                />
              </div>
              <div>
                <label className="mx-3">Website</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  value="mobileapp"
                  name="projectcategory"
                  checked={product.projectcategory.includes('mobileapp')}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      setProduct({
                        ...product,
                        projectcategory: [...product.projectcategory, e.target.value],
                      });
                    } else {
                      setProduct({
                        ...product,
                        projectcategory: product.projectcategory.filter(
                          (category) => category !== e.target.value
                        ),
                      });
                    }
                  }}
                />
              </div>
              <div>
                <label className="mx-3">Mobile app</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-control">
          <label>Status</label>
          <div className="row mt-3">
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="radio"
                  name="status"
                  value="ongoing"
                  checked={product.status === 'ongoing'}
                  onChange={(e) =>
                    setProduct({ ...product, status: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="mx-3"> Ongoing</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="radio"
                  name="status"
                  value="completed"
                  checked={product.status === 'completed'}
                  onChange={(e) =>
                    setProduct({ ...product, status: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="mx-3"> Completed</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-control">
          <label>modules</label>
          <input
            type="text"
            name="modules"
            value={product.modules}
            onChange={(e) =>
              setProduct({ ...product, modules: e.target.value })
            }
            placeholder="Enter Modules"
          />
        </div>
        <div class="form-control">
          <label>Milestone</label>
          <input
            type="text"
            name="milestone"
            value={product.milestone}
            onChange={(e) =>
              setProduct({ ...product, milestone: e.target.value })
            }
            placeholder="Enter Milestone"
          />
        </div>

        {!isLoading && (
          <Button type="submit" onClick={onSubmit}>
            Update
          </Button>
        )}
      </form>
    </div>
  );
}

export default EditProject;
