import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import { env } from "../envconfig";
import moment from "moment";
import ReactPaginate from "react-paginate";

function Assignee() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [isContact, setIsContact] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  var limit = 5;

  useEffect(() => {
    getAssigneeTable();
  }, []);

  const getAssigneeTable = async (currentPage) => {
    try {
      let token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.get(`${BACKEND_URL}/user/assigneetable?_page=${currentPage}&_limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.details)
      setIsContact(response.data.details.Deta1);
      const total = response.data.details.Deta2;
        setPageCount(Math.ceil(total / 5));
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };

  const handlePageClick = async (data) => {
    // console.log (data.selected);
    let currentPage = data.selected + 1;
    getAssigneeTable(currentPage);
  };

  const history = useHistory();

  const createAssignee =() => {
    history.push("/admin/sub-admin");
  };

  const createContact = () => {
    history.push("/admin/sub-admin");
  };

  const deleteAssignee = async (id) => {
    const result = await Swal.fire({
      title: "Do you really want to delete the Contact ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        let token = localStorage.getItem("authToken");
        // console.log(id,"==res==")
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/deleteassignee/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Deleted Assignee successfully");
        getAssigneeTable();
        setIsLoading(false);
      } catch (error) {
        // console.log(error)
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="contactContainer">
        <div className="createbtncss">
          <Button onClick={createAssignee} class="btn btn-primary">
            Create
          </Button>
        </div>
      <div>
        <table class="table table-fixed ">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="11">
                  <div className="text-center p-3 my-5">
                    <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {isContact.length > 0 ? (
                  <>
                    {isContact.map((contact, index) => (
                      <tr>
                        <td>
                          <p key={index}>
                            <span> {index + 1}</span>
                          </p>{" "}
                        </td>
                        <td> {moment(contact.createdAt).format("lll")} </td>
                        <td>{contact.fullName}</td>
                        <td>{contact.email}</td>
                        <td>{contact.userType}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <Link to={`/editAssignee/${contact._id}`}>
                              <i class=" pencss text-primary fa-solid fa-pen"></i>{" "}
                            </Link>
                            <i
                              class=" dltcss text-danger fa-solid fa-trash"
                              onClick={() => deleteAssignee(contact._id)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="11">
                        <div className="text-center align-items-center justify-content-center p-3 my-5">
                          {/* there is no product  */}

                          <p className="datacss">No Datas Found !</p>
                          <p className="my-3">
                            There is no datas to show you now
                          </p>
                          <div>
                            <Button
                              onClick={createContact}
                              class="btn btn-primary"
                            >
                              Create
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <div className="paginationcss">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"***"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-md justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
      </div>
    </div>
  );
}

export default Assignee;
