import React, { useState } from "react";
import { Button } from "react-bootstrap";
// import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
// import useStateRef from "react-usestateref";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { env } from "../envconfig";

function NotesForm() {
    const BACKEND_URL = env.BACKEND_URL;
  
    const [isLoading, setIsLoading] = useState("");
    const history = useHistory();
  
    const validateValues = z.object({
      name: z
        .string()
        .min(1, { message: "Name feild is required !" }),
      title: z
        .string()
        .min(1, { message: "Title feild is required !" }),
      lastdate: z.string().min(1, { message: "Last Date is required !" }),
    });
  
    //length(1,{message:"Assignment is required !"})
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      resolver: zodResolver(validateValues),
    });
  
    const onSubmit = async (data) => {
      try {
        let token = localStorage.getItem("authToken");
        //console.log("inside clicked", data)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsLoading(true);
        axios
          .post(`${BACKEND_URL}/user/notesDetails`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            //console.log(response,"-=-=-=-=-=--=response")
            toast.success("Details Created successfully");
            history.push("/admin/notes");
            reset();
          })
          .catch((error) => {
            //console.log(error);
            toast.error("Details does 2222 not Created");
          });
      } catch (error) {
        //console.log(error)
        toast.error("Details does not got");
        setIsLoading(false);
      }
    };
  
    const onCancel = () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      reset();
    };
  
    const cancelFn = () => {
      history.push("/admin/notes");
    };
  
    return (
      <div className="contactformcontainer">
        <form className="form">
          <div className="cancelcss">
            <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
          </div>
          <div className="contacthead text-center mb-3">
            <h1>Create Details</h1>
          </div>
          <div className="form-control">
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              {...register("name")}
            />
            {errors?.name && (
              <p className="errorcss">{errors.name?.message}</p>
            )}
          </div>
          <div class="form-control">
            <label>Title</label>
            <input
              type="text"
              name="title"
              placeholder="Enter Title"
              {...register("title")}
            />
            {errors?.title && (
              <p className="errorcss">{errors.title?.message}</p>
            )}
          </div>
          <div class="form-control">
            <label>Last Date</label>
            <input
              type="date"
              name="lastdate"
              placeholder="Enter Last Date"
              {...register("lastdate")}
            />
            {errors?.lastdate && (
              <p className="errorcss">{errors.lastdate?.message}</p>
            )}
          </div>
  
          {!isLoading && (
            <>
              <div className="row justify-content-center mb-3">
                <div className="col-lg-2 my-2 mx-3">
                  <Button onClick={onCancel}>Cancel</Button>
                </div>
                <div className="col-lg-2 my-2 mx-3">
                  <Button type="submit" onClick={handleSubmit(onSubmit)}>
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    );
  }

export default NotesForm;