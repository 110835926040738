import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import SignIn from '../src/views/auth/signIn/index';
import editContact from '../src/views/auth/signIn/editContact';
import editProject from '../src/views/auth/signIn/editProject';
import EditAssignee from '../src/views/auth/signIn/editAssignee';
import EditByAssignee from '../src/views/auth/signIn/editByAssignee';
import EditComments from '../src/views/auth/signIn/editComments';
import AddTask from '../src/views/auth/signIn/addTasks';
import ContactForm from 'components/contactForm';
import ProjectForm from 'components/ProjectForm';
import NotesForm from 'components/NotesForm';
import { removeAuthToken } from 'lib/localStorage';


function RequireAuth({ children }) {
	var data = localStorage.getItem("authToken");
	return data ? children : removeAuthToken();
}

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <ThemeEditorProvider>
                <Router>
                    <Switch>
                        <Route exact path="/" component={SignIn} />
                        <Route path={`/auth`} component={AuthLayout} />
                        <Route
                            path="/admin"
                            component={() => (
                                <RequireAuth>
                                    <AdminLayout />
                                </RequireAuth>
                            )}
                        />
                        <Route path={`/rtl`} component={RtlLayout} />
                        <Route path={`/edit/:id`} component={editContact} />
                        <Route path={`/editproject/:id`} component={editProject} />
                        <Route path={`/editAssignee/:id`} component={EditAssignee} />
                        <Route path={`/editComments/:id`} component={EditComments} />
                        <Route path={`/editByAssignee/:id`} component={EditByAssignee} />
                        <Route path={`/contact-form`} component={ContactForm} />
                        <Route path={`/project-form`} component={ProjectForm} />
                        <Route path={`/addTasks/:id`} component={AddTask} />
                        <Route path={`/notes-form`} component={NotesForm} />
                    </Switch>
                  </Router>
                <ToastContainer />
            </ThemeEditorProvider>
        </React.StrictMode>
    </ChakraProvider>,
    document.getElementById('root')
);
