// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { env } from "../../../../envconfig";
import { useHistory } from "react-router-dom";
// Assets
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/profile/components/Dropzone";

export default function Upload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";

  const BACKEND_URL = env.BACKEND_URL;
  const history = useHistory();

  const initialFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [oldPasswordErr, setOldPasswordErr, setOldPasswordErrref] = useState(false);
  const [newPasswordValidate, setNewPasswordValidate, newPasswordValidateref] = useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { oldPassword, newPassword ,confirmPassword } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    let errors = {};

    if (formValue.oldPassword == "") {
      setOldPasswordErr(true);
      errors.oldPassword = " Old Password is a required field !";
    }else {
      setOldPasswordErr(false);
    }

    if (values.newPassword == "") {
        setNewPasswordValidate(true);
        errors.newPassword = "New Password is a required field !";
      }else {
        setNewPasswordValidate(false);
      }
  

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field !";
      setconfirmPasswordValidate(true);
    } else if (
      values.newPassword &&
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    setvalidationnErr(errors);

    return errors;
  };

  const onSubmit= async() => {
    try {
      validate(formValue);
      if (
          formValue.oldPassword != "" &&
          formValue.newPassword != "" &&
          formValue.confirmPassword != "" &&
          confirmPasswordValidate == false
      ){
        console.log(formValue,"-=-=-");
        let token = localStorage.getItem("authToken");
        setbuttonLoader(true);
        const response = await axios.post(`${BACKEND_URL}/user/changePassword`,formValue,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.data.status != true){ 
          setbuttonLoader(false);
          toast.error(response.data.message);
          // setFormValue(initialFormValue);
        }else{
          setbuttonLoader(false);
          toast.success(response.data.message);
          setFormValue(initialFormValue);

        }
      }
      
    } catch (error) {
      setbuttonLoader(false);
    }
   
  }

  return (
    <Card {...rest} mb='20px' align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        {/* <Dropzone
          w={{ base: "100%", "2xl": "268px" }}
          me='36px'
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          content={
            <Box>
              <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='xl' fontWeight='700' color={brandColor}>
                  Upload Files
                </Text>
              </Flex>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                PNG, JPG and GIF files are allowed
              </Text>
            </Box>
          }
        /> */}
        <Flex direction='column' className="profileChange" pe='44px'>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            className="changeexcelheading"
            mt={{ base: "20px", "2xl": "50px" }}>
            Change your Excel password here .
          </Text>
          {/* <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            mx='auto'
            textAlign='start'>
            Stay on the pulse of distributed projects with an anline whiteboard
            to plan, coordinate and discuss
          </Text> */}
          {/* <Flex w='100%'>
            <Button
              me='100%'
              mb='50px'
              w='140px'
              minW='140px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'>
              Publish now
            </Button>
          </Flex> */}
          <div className="text-start">
          <form className="form">
          <div className="form-control">
          <label>Old password</label>
          <input
            type="text"
            placeholder="Enter Old password"
            name="oldPassword"
            value={oldPassword}
            onChange={handleChange}
          />
          {/* {errors?.newpassword && <p className="errorcss">{errors.newpassword?.message}</p>} */}
        </div>
        {oldPasswordErr == true ? (
          <p className="errorcssexcel">
            {" "}
            {validationnErr.oldPassword}{" "}
          </p>
        ) : (
          ""
        )}
          <div className="form-control">
          <label>New password</label>
          <input
            type="text"
            placeholder="Enter new password"
            name="newPassword"
            value={newPassword}
            onChange={handleChange}
          />
          {/* {errors?.newpassword && <p className="errorcss">{errors.newpassword?.message}</p>} */}
        </div>
        {newPasswordValidate == true ? (
          <p className="errorcssexcel">
            {" "}
            {validationnErr.newPassword}{" "}
          </p>
        ) : (
          ""
        )}
        <div className="form-control">
          <label>Confirm Password</label>
          <input
            type="text"
            placeholder="Enter confirm password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
            
          />
          {/* {errors?.confirmpassword && <p className="errorcss">{errors.confirmpassword?.message}</p>} */}
        </div>
        {confirmPasswordValidate == true ? (
          <p className="errorcssexcel">
            {" "}
            {validationnErr.confirmPassword}{" "}
          </p>
        ) : (
          ""
        )}
        {/* {!isLoading && ( */}
          <>
           
              <div className="mt-4 mx-3">
              {buttonLoader == false ? (
                <Button className="mt-3" onClick={onSubmit}>Submit</Button>
              ) : (
                <Button>Loading...</Button>
              )}
              </div>
          </>
        {/* )} */}
          </form>
          </div>
        </Flex>
      </Flex>
    </Card>
  );
}
