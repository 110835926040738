// //  DEVELOPMENT_ENVIRONMENT
//  export const  env = { 
//  RECAPTCHA_SITE_KEY : "6Ldyhj0pAAAAALw1E3nPCWOAxdlPJXWjgGoKDOJi",
//  BACKEND_URL : "http://localhost:3001"
// }


// PRODUCTION_ENVIRONMENT

export const  env = { 
    RECAPTCHA_SITE_KEY : "6LcjYYcpAAAAANVTXr3RnhZMsfr8MreBI1uxBBan",
    BACKEND_URL : "https://bvfthfvgjyyhbghvyhgv57vfc.beleaftechnologies.com:3001"
}