import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, useParams } from "react-router-dom";
import { env } from "../../../envconfig";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';


function AddTasks() {
    const BACKEND_URL = env.BACKEND_URL;
    let { id } = useParams();
    const [isLoading, setIsLoading] = useState("");
    const [verifyAdmin, setverifyAdmin] = useState("");
  
    const history = useHistory();

    const [tasksList, setTasksList] = useState([]);
  
    const [statuserror, setStatuserror,statuserrorref] = useStateRef("");
    const [initialState,setInitialState] = useState(false);
  
    useEffect(() => {
      getdetails();
    }, []);
  
    const getdetails = () => {
      try {
        let token = localStorage.getItem("authToken");
        setIsLoading(true);
        axios
        .get(`${BACKEND_URL}/user/tasksList/${id}`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log(response.data.details.tasks,"response.data.details");
          var TasksReverse = response.data.details.tasks.reverse()
          setTasksList(TasksReverse);
        })
        .catch((error) =>{
          console.log(error.message);
        })
      } catch (error) {
        console.log(error.message);
        setIsLoading(false);
      }  
    };

    const [initialFormValue ,setInitialFormValue]= useState({
      title:"",
      details:"",
      date:""
    })

    const handleChange = (e) => {
      console.log(e.target.value, "e");
      setInitialFormValue({ ...initialFormValue, [e.target.name]: e.target.value });
    };

    const changeStatus = () => {
      setInitialState(true);
    }

    const backfn = () => {
      setInitialState(false);
      getdetails();
    }
  
    const cancelFn = () => {
      history.push("/admin/notes");
    };
  
  
    const onSubmit = async () => {
      initialFormValue["date"] = startDate;
      console.log("inside clicked", initialFormValue);
      try {
        let token = localStorage.getItem("authToken");
        setIsLoading(true);
        axios
        .post(`${BACKEND_URL}/user/Taskdetails/${id}`, initialFormValue, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          //console.log(response,"-=-=-=-=-=--=response")
          toast.success("Tasks created successfully");
          setInitialFormValue("");
          setInitialState(false);
          setIsLoading(false);
          getdetails();
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Task does not created");
          setIsLoading(false);
        });
      } catch (error) {
        toast.error("Task does not created");
        setIsLoading(false);
      }
    };

    const [startDate, setStartDate] = useState(new Date());

    let handleColor = (time) => {
      return time.getHours() > 12 ? "text-success" : "text-error";
    };

    const SelectRadio = (data) => {
      console.log(data,"-=-e=-=");
      try {
        // const obj = tasksList.length - data ;

       const Deltask =  tasksList.splice(data, 1);

        const index ={
          id : id,
          detail : tasksList
        };
        console.log(index,"-=-index-=-");
        let token = localStorage.getItem("authToken");
        setIsLoading(true);
        axios
        .post(`${BACKEND_URL}/user/Taskcompleted/${id}`, index, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          //console.log(response,"-=-=-=-=-=--=response")
          toast.success("Task Completed");
          setIsLoading(false);
          getdetails();
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Task does not Completed");
          setIsLoading(false);
        });
      } catch (error) {
        toast.error("Task does not Completed");
          setIsLoading(false);
      }
    }

    
  
    return (
      <div className="contactformcontainer">
        <form>
          <div className="cancelcss mt-3 ml-5">
            <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
          </div>
          <div className="contactheadnotes text-center mb-3" onClick={changeStatus}>
            <span><i class="fa-solid fa-circle-check"></i></span>
            <h3>Add a task</h3>
          </div>

          {initialState == true ? ( 
            <>
               <div className="Dropdowntask">
                  <div>
                  <textarea
                  rows={2}
                  cols="50"
                  name="title"
                  autoFocus
                  placeholder="Title"
                  value={initialFormValue.title}
                  onChange={handleChange}
                  />
                <textarea
                  rows={2}
                  cols="50"
                  name="details"
                  placeholder="Details"
                  value={initialFormValue.details}
                  onChange={handleChange}
                  />
                   <DatePicker
                      showTimeSelect
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      timeClassName={handleColor}
                      placeholderText="Date / Time"
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  {/* <input type="date" className="datetask"/> */}
                  </div>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-lg-2 my-2 mx-3">
                    <Button onClick={backfn}>Cancel</Button>
                  </div>
                  <div className="col-lg-2 my-2 mx-3">
                    <Button onClick={onSubmit}>
                      Submit
                    </Button>
                  </div>
                </div>
            </>
          ):(
            <>
            {tasksList.length > 0 ? (
              <> 
              {tasksList.map((item,index)=>(
                 <div className="Dropdowntask">
                 <div className="taskradio">
                   <input type="checkbox" onClick={()=>SelectRadio(index)} title="Mark as completed"/>
                 </div>
                 <div>
                  <table className="tasktable">
                    <tr>
                      <td>Title : </td>
                      <td>{item.title}</td>
                    </tr>
                    <tr>
                      <td>Detail : </td>
                      <td>{item.details}</td>
                    </tr>
                    <tr>
                      <td>Date : </td>
                      <td>{moment(item.date).format("lll")}</td>
                    </tr>
                  </table>
                   {/* <p>Title : {item.title}</p>
                   <p>Detail : {item.details}</p>
                   <p>Date : {item.date}</p> */}
                 </div>
               </div>
              ))}
              </>
            ):(
              <>
              <div>
                 <div className="taskimg">
                   <img src={require("../../../assets/img/Group 1 (1).png")} height="200px" width="250px" />
                 </div>
                 <div className="notaskcss">
                 <span>No tasks yet</span>
                 </div>
               </div>
             </>
            )}
           
            </>
           
          )}

        
        </form>
      </div>
    );
  }

export default AddTasks;