import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import { env } from "../envconfig";
import ReactPaginate from "react-paginate";
import { IoTrophyOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';


function Priority() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isContact, setIsContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyAdmin, setverifyAdmin] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [counts,setCounts] = useState("");
  const [activeClass,setactiveClass] = useState("High");

  const [search, setsearch, searchref] = useStateRef("");
  const [searchEmpt,setSearchEmpt,searchEmptref] = useStateRef(false);
  const initialFormValue = {
    fromdate: "",
    todate: "",
    priority: "",
  };
  const [formValue, setFormValue, setFormRef] = useStateRef(initialFormValue);
  const { fromdate, todate, priority } = formValue;

  var limit = 50;

  useEffect(() => {
     getDetails("High");
  }, []);

  const getDetails = async (currentPage) => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }
      setverifyAdmin(decoded.userType);

      // console.log(token ,"==--token --==")
      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        // console.log(decoded.user_id,"==userid==")
        setIsLoading(true);
        // console.log(setFormRef.current['priority']="High",'setFormRef.current');
        let setPriority = currentPage;
        setFormRef.current['priority']=setPriority
        console.log(setFormRef.current);
        const response = await axios.post(
          `${BACKEND_URL}/user/getPriorityApi?_page=${1}&_limit=${limit}`,setFormRef.current,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsContact(response.data.details.Deta1);
        setCounts(response.data.details.Deta2);
        const total = response.data.details.Deta2;
        // console.log(counts,"counts--0-");
        setPageCount(Math.ceil(total / 5));
        // console.log(pageCount,"=-=-=-=")
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };



  // const [selectedDate, setSelectedDate] = useState("");

  const handleChange = (e) =>{
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    getDetails();
  }

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    getDetails(currentPage);
  };

  const history = useHistory();


  const viewdata = async (id) => {
    // console.log(id,"id");
    const result = await Swal.fire({
      title: "More Details",
      // className:"swalCss",
      width: '700px',
      html: `
        <table  class="table table-fixed" border="1">
        
          <tbody>
            <tr>
              <td>Name :</td>
              <td>${id.name}</td>
            </tr>
          <tr>
          <td>Channel :</td>
          <td>${id.channel}</td>
          </tr>
          <tr>
          <td>Country :</td>
          <td>${id.country}</td>
          </tr>
          <tr>
          <td>Contact :</td>
          <td>${id.contact}</td>
          </tr>
          <tr>
          <td>Date :</td>
          <td>${id.date}</td>
          </tr>
          <tr>
          <td>Category :</td>
          <td>${id.category}</td>
          </tr>
          <tr>
          <td>SubCategory :</td>
          <td>${id.subcategory}</td>
          </tr>
          <tr>
          <td>status :</td>
          <td>${id.status}</td>
          </tr>
          <tr>
          <td>Description :</td>
          <td>${id.description}</td>
          </tr>
          </tbody>
        </table>
      `,
      confirmButtonText: "Close",
    });
  };


  const downloadData = async () => {
    const result = await Swal.fire({
      title: "Enter Your Password",
      input: 'text',
      inputPlaceholder: 'Enter your name here',
      showCancelButton: true,
      confirmButtonText: 'Download',
      cancelButtonText: 'Cancel',
    });
    if(result.isConfirmed){
      const name = result.value;
      try {
        var obj ={
          Name : name,
        }
        let token = localStorage.getItem("authToken");  
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/getExcelpermission`, obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if(response.data.status == true){
            var obj ={
                Name : name,
                priority : activeClass
              }
              let token = localStorage.getItem("authToken");  
              const responsePriority = await axios.post(
                `${BACKEND_URL}/user/downloadPriority`, obj,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              console.log(responsePriority.data,'-=-=responsePriority==-');
            
          const fieldsToExport = ['name', 'contact', 'country','status','category','channel','date','altercontact','subcategory','description','comment'];
        //   return false
          const filteredData = responsePriority.data.details.map(item => {
            const filteredItem = {};
            fieldsToExport.forEach(field => {
              filteredItem[field] = item[field];
            });
              return filteredItem;
          })
          const worksheet = XLSX.utils.json_to_sheet(filteredData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'My_File.xlsx');
        //   getDetails();
          toast.success("File is Downloading");
          setIsLoading(false);
        }else{
        //   getDetails();
          setIsLoading(false);
          toast.error("Password is incorrect , Please enter a correct password");
        }
      } catch (error) {
        setIsLoading(false);
      }
    } 
  }


  const [contactType, setContactType] = useState(true);

  const handleChangeInput = (e) => {
    try {
      console.log(e.target.value, "eeeeeeeeeeeeeeeeeeeeeeeee");
      setsearch(e.target.value);
      searchWalletList();
    } catch (error) { }
  };

  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = isContact.filter((data) =>
        regexPattern.test(data.name)
      );
      if (searchWallet.length > 0) {
        setIsContact(searchWallet);
        // setDataHide(false);
        // settotal(1);
      } else {
        console.log("Its come in else part -----");
        setIsContact(searchWallet);
        // getUserbalance(1);
      }
    } else {
      console.log("Its come in second part -----");
      getDetails();
      // getUserbalance(1);
    }
  };

  const getAllContacts = async() => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }

      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
        // console.log(decoded.user_id,"==userid==")
        setIsLoading(true);
        const response = await axios.get(
          `${BACKEND_URL}/user/getAllContacts`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsContact(response.data.details.Deta1);
        setCounts(response.data.details.Deta2);
        setFormValue(initialFormValue);
        setSearchEmpt(true);
        
        setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };
  const classSet = (value) => {
    try {
        setactiveClass(value);
        getDetails(value);
    } catch (error) {
        
    }
  }



  return (

    
    <div className="contactContainer">

<div class="prioritytab">
                <ul class="nav nav-tabs">
                    <li class={activeClass == 'High'? "active" : "Deactive"} onClick={() => classSet("High")} ><a data-toggle="tab" >High</a></li>
                    <li class={activeClass  == 'Medium'? "active" : "Deactive" } onClick={() => classSet("Medium")}><a data-toggle="tab" >Medium</a></li>
                    <li class={activeClass  == 'Rejected'? "active" : "Deactive"} onClick={() => classSet("Rejected")}><a data-toggle="tab" > Rejected </a></li>
                </ul>
 </div>

 <div>
        
      <div className="createbtncss_contact">



      {verifyAdmin == "admin" ? (
        <>
          


          <div className="countsright">
          <div className="input_border">
                          <div className="mt-1" onClick={() => searchWalletList()}>
                          <i class="fa-solid fa-magnifying-glass fa-xs"></i>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Search by name"
                              name="searchvallist"
                              onChange={handleChangeInput}
                              className="contact_search"
                            />
                          </div>
                        </div>
            </div>
          <div className="countsright">
            <span>From : </span>
            <Form.Control
                type="date"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="filterdatacss"
                value={fromdate}
                onChange={handleChange}
                name="fromdate"
              />
            </div>
            <div className="countsright">
            <span>To : </span>
            <Form.Control
                type="date"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                className="filterdatacss"
                value={todate}
                onChange={handleChange}
                name="todate"
              />
            </div>
          <div className="countsright">
            <span>Counts : </span>
            <Button class="btn btn-outline-primary " >{counts}</Button>
          </div>
        
              <div className="countsright mb-0 ml-1" style={{cursor:"pointer"}}>
                <i class="fa-solid fa-download fa-lg" onClick={downloadData}></i>
              </div>
              </>
      ) : (
        ""
      )}
          <div className="countsright123">
              <Button onClick={getAllContacts} class="btn btn-primary">
                All
              </Button>
          </div>
          </div>
      <div>

        <table class="table table-fixed ">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th> 
              <th>Category</th>
              <th>Status</th>
              {verifyAdmin == "admin" ? (
                <>
                  <th>Assignees</th>
                  <th>Action</th>
                </>
              ) : (
                <>
                  <th>Action</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="11">
                  <div className="text-center p-3 my-5">
                    <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {isContact.length > 0 ? (
                  <>
                    {isContact.map((contact) => (
                      <tr>
                        <td className="justify-content-center">
                          {contact.name}
                        </td>
                        <td>{contact.date}</td>
                        <td>{contact.category}</td>
                        <td>{contact.status}</td>
                        {verifyAdmin == "admin" ? (
                          <>
                            <td>
                              {contact.assignee.map((name) => (
                                <ul className="licss">
                                  <li>{name.fullName}</li>
                                </ul>
                              ))}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <i
                                  class="fa-regular fa-eye dltcss text-dark"
                                  onClick={() => viewdata(contact)}
                                ></i>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <div>
                                <Link to={`/editByAssignee/${contact._id}`}>
                                  <i class=" pencss text-primary fa-solid fa-pen"></i>{" "}
                                </Link>
                                <i
                                  class="fa-regular fa-eye dltcss text-dark"
                                  onClick={() => viewdata(contact)}
                                ></i>
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="11">
                        <div className="text-center align-items-center justify-content-center p-3 my-5">
                          <p className="datacss">No Datas Found !</p>
                          <p className="my-3">
                            There is no datas to show you now
                          </p>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>

        {isContact.length > 0 && searchEmptref.current == false ? (
          <div className="paginationcss">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"***"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination pagination-md justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          ""
        )}
       
      </div>
      </div>

    </div>
  );
}

export default Priority;
