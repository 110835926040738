import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { AtSignIcon,ArrowRightIcon,PlusSquareIcon,HamburgerIcon,CheckCircleIcon } from '@chakra-ui/icons'

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import SubAdmin from "components/SubAdmin";
import ContactTable from 'components/contactTable'
import Assignee from "components/Assignee";
import ProjectTable from "components/projectTable";
import Comments from "components/comments";
import Profile from "views/admin/profile";
import Notes from "components/Notes";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import Priority from "components/Priority";
import { jwtDecode } from "jwt-decode";


// Auth Imports
import SignInCentered from "views/auth/signIn";

let token = localStorage.getItem("authToken")

let decoded;

if(token){
   decoded = jwtDecode(token)

}

let routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    isSubAdmin : false ,
    isDigital : false ,
    isProject : false ,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },

 
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    isSubAdmin : false ,
    isDigital : false ,
    isProject : false ,
  },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  //   isSubAdmin : true ,
  // },
  {
  name: "Create Assignee",
  layout: "/admin",
  path: "/sub-admin",
  isSubAdmin : false ,
  isDigital : false ,
  isProject : false ,
  icon: (
    <Icon
      as={ArrowRightIcon}
      width='15px'
      height='15px'
      color='inherit'
    />
  ),
  component: SubAdmin,
  secondary: true,
},
{
  name: "Assignees",
  layout: "/admin",
  path: "/assignee-table",
  isSubAdmin : false ,
  isDigital : false ,
  isProject : false ,
  icon: (
    <Icon
      as={AtSignIcon}
      width='20px'
      height='20px'
      color='inherit'
    />
  ),
  component: Assignee,
  secondary: true,
},
// {
//   name: "Contact Form",
//   layout: "/admin",
//   path: "/contact-form",
//   icon: (
//     <Icon
//       as={MdOutlineShoppingCart}
//       width='20px'
//       height='20px'
//       color='inherit'
//     />
//   ),
//   component: contactForm,
//   secondary: true,
//   isSubAdmin : false ,
// },
{
  name: "Contacts",
  layout: "/admin",
  path: "/contact-table",
  isSubAdmin : true ,
  isDigital : true ,
  isProject : true ,
  icon: (
    <Icon
      as={PlusSquareIcon}
      width='20px'
      height='20px'
      color='inherit'
    />
  ),
  component: ContactTable,
  secondary: true,

},
{
  name: "Project",
  layout: "/admin",
  path: "/project-table",
  isSubAdmin : true ,
  isDigital : true ,
  isProject : false ,
  icon: (
    <Icon
      as={MdOutlineShoppingCart}
      width='20px'
      height='20px'
      color='inherit'
    />
  ),
  component: ProjectTable,
  secondary: true,
},
{
  name: "Comments",
  layout: "/admin",
  path: "/comments",
  isSubAdmin : false ,
  isDigital : false ,
  isProject : false ,
  icon: (
    <Icon
      as={HamburgerIcon}
      width='20px'
      height='20px'
      color='inherit'
    />
  ),
  component: Comments,
  secondary: true,
},
{
  name: "Notes",
  layout: "/admin",
  path: "/notes",
  isSubAdmin : false ,
  isDigital : false ,
  isProject : false ,
  icon: (
    <Icon
      as={CheckCircleIcon}
      width='15px'
      height='15px'
      color='inherit'
    />
  ),
  component: Notes,
  secondary: true,
},
{
  name: "Priority",
  layout: "/admin",
  path: "/priority",
  isSubAdmin : false ,
  isDigital : false ,
  isProject : false ,
  icon: (
    <Icon
      as={CheckCircleIcon}
      width='15px'
      height='15px'
      color='inherit'
    />
  ),
  component: Priority,
  secondary: true,
},

// {
//   name: "Priority",
//   layout: "/admin",
//   path: "/priority",
//   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//   component: Priority,
//   isSubAdmin : false ,
//   isDigital : false ,
//   isProject : false ,
// },
// {
//   name: "Details",
//   layout: "/admin",
//   path: "/assignee-details",
//   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
//   component: AssigneeDetails,
//   isSubAdmin : true ,
// },
];
// console.log(decoded, "decodeds")

// if( decoded?.userType === "admin"){
//     routes = [...routes,  {
//       name: "Sub Admin",
//       layout: "/admin",
//       path: "/sub-admin",
//       icon: (
//         <Icon
//           as={MdOutlineShoppingCart}
//           width='20px'
//           height='20px'
//           color='inherit'
//         />
//       ),
//       component: SubAdmin,
//       secondary: true,
//     },
//     {
//       name: "Contact Form",
//       layout: "/admin",
//       path: "/contact-form",
//       icon: (
//         <Icon
//           as={MdOutlineShoppingCart}
//           width='20px'
//           height='20px'
//           color='inherit'
//         />
//       ),
//       component: contactForm,
//       secondary: true,
//     },
//   ]
// }




export default routes;
