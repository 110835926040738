import React, { useState } from "react";
import { Button } from "react-bootstrap";
// import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
// import useStateRef from "react-usestateref";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { env } from "../envconfig";

function ProjectForm() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isLoading, setIsLoading] = useState("");
  const history = useHistory();

  const validateValues = z.object({
    projectname: z
      .string()
      .min(1, { message: "Project Name feild is required !" }),
    clientname: z
      .string()
      .min(1, { message: "Client Name feild is required !" }),
    managername: z.string().min(1, { message: "Manager Name is required !" }),
    team: z.string().min(1, { message: "Team is required !" }),
    projectcategory: z.string().array().min(1),
    // .min(0,{message:"projectcategory is required !"}),
    modules: z.string().min(1, { message: "Modules is required !" }),
    milestone: z.string().min(1, { message: "Milestone is required !" }),
    status: z.string().optional(),
    // .nullish({message:"Status is required !"}),
    // .min(1,{message:"status is required !"}),
  });

  //length(1,{message:"Assignment is required !"})
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validateValues),
  });

  const onSubmit = async (data) => {
    try {
      let token = localStorage.getItem("authToken");
      //console.log("inside clicked", data)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsLoading(true);
      axios
        .post(`${BACKEND_URL}/user/project`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          //console.log(response,"-=-=-=-=-=--=response")
          toast.success("Poject created successfully");
          history.push("/admin/project-table");
          reset();
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Details does not send");
        });
    } catch (error) {
      //console.log(error)
      toast.error("Details does not got");
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    reset();
  };

  const cancelFn = () => {
    history.push("/admin/project-table");
  };

  return (
    <div className="contactformcontainer">
      <form className="form">
        <div className="cancelcss">
          <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
        </div>
        <div className="contacthead text-center mb-3">
          <h1>Create Project</h1>
        </div>
        <div className="form-control">
          <label>Project Name</label>
          <input
            type="text"
            name="projectname"
            placeholder="Enter Project name"
            {...register("projectname")}
          />
          {errors?.projectname && (
            <p className="errorcss">{errors.projectname?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Client Name</label>
          <input
            type="text"
            name="clientname"
            placeholder="Enter Client Name"
            {...register("clientname")}
          />
          {errors?.clientname && (
            <p className="errorcss">{errors.clientname?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Manager Name</label>
          <input
            type="text"
            name="managername"
            placeholder="Enter Manager Name"
            {...register("managername")}
          />
          {errors?.managername && (
            <p className="errorcss">{errors.managername?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Team</label>
          <input
            type="text"
            name="team"
            placeholder="Enter Team members"
            {...register("team")}
          />
          {/* <div id="emailList"></div> */}
          {errors?.team && <p className="errorcss">{errors.team?.message}</p>}
        </div>
        <div class="form-control">
          <label>Project Category</label>
          <div className="row mt-3">
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  value="website"
                  name="projectcategory"
                  {...register("projectcategory")}
                />
              </div>
              <div>
                <label className="mx-3">Website</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  value="mobileapp"
                  name="projectcategory"
                  {...register("projectcategory")}
                />
              </div>
              <div>
                <label className="mx-3"> Mobile app</label>
              </div>
            </div>
          </div>
          {errors?.projectcategory && (
            <p className="errorcss">Project Category is required !</p>
          )}
        </div>

        <div class="form-control">
          <label>Status</label>
          <div className="row mt-3">
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="radio"
                  name="status"
                  value="ongoing"
                  {...register("status")}
                />
              </div>
              <div>
                <label className="mx-3"> Ongoing</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="radio"
                  name="status"
                  value="completed"
                  {...register("status")}
                />
              </div>
              <div>
                <label className="mx-3"> Completed</label>
              </div>
            </div>
          </div>
          {errors?.status && <p className="errorcss">Status is Required !</p>}
        </div>

        <div class="form-control">
          <label>Modules</label>
          <textarea
            rows={2}
            name="modules"
            placeholder="Enter Modules"
            {...register("modules")}
          />
          {errors?.modules && (
            <p className="errorcss">{errors.modules?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Milestone</label>
          <textarea
            rows={2}
            name="milestone"
            placeholder="Enter Milestone"
            {...register("milestone")}
          />
          {errors?.milestone && (
            <p className="errorcss">{errors.milestone?.message}</p>
          )}
        </div>

        {!isLoading && (
          <>
            <div className="row justify-content-center mb-3">
              <div className="col-lg-2 my-2 mx-3">
                <Button onClick={onCancel}>Cancel</Button>
              </div>
              <div className="col-lg-2 my-2 mx-3">
                <Button type="submit" onClick={handleSubmit(onSubmit)}>
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default ProjectForm;
