import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import { env } from "../envconfig";
import ReactPaginate from "react-paginate";


function ProjectTable() {
  const BACKEND_URL = env.BACKEND_URL;
  const history = useHistory();

  const [isContact, setIsContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyAdmin,setverifyAdmin] = useState("");
  const [pageCount, setPageCount] = useState(0);

  var limit = 5;

  useEffect(async () => {
    await getDetails();
  }, []);

  const getDetails = async (currentPage) => {
    try {
      let decoded;
      let token = localStorage.getItem("authToken");
      if (token) {
        decoded = jwtDecode(token);
      }
      setverifyAdmin(decoded.userType);
      if (decoded.user_id !== "" && decoded.user_id !== undefined) {
      setIsLoading(true);
      const response = await axios.get(`${BACKEND_URL}/user/projecttable?_page=${currentPage}&_limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response);
      let result = response.data.details.Deta1.map((item) => {
        return {
          ...item,
          teamMembers: item.team !== ""  && item.team != undefined ? 
            item.team.split(",")
          : 
            ""
        };
      });
      // console.log(result);
      setIsContact(result);
      const total = response.data.details.Deta2;
        setPageCount(Math.ceil(total / 5));
      setIsLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setIsLoading(false);
    }
  };

  const handlePageClick = async (data) => {
    // console.log (data.selected);
    let currentPage = data.selected + 1;
    getDetails(currentPage);
  };



  const createContact = () => {
    history.push("/project-form");
  };

  const deleteContact = async (id) => {
    const result = await Swal.fire({
      title: "Do you really want to delete the Project ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        let token = localStorage.getItem("authToken");
        // console.log(id,"==res==")
        setIsLoading(true);
        const response = await axios.post(
          `${BACKEND_URL}/user/deleteproject/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Deleted successfully");
        getDetails();
        setIsContact(response.data.details);
        setIsLoading(false);
      } catch (error) {
        // console.log(error)
        setIsLoading(false);
      }
    }
  };

  const viewdata = async (id) => {
    // console.log(id,"id");
    const result = await Swal.fire({
      title: "More Details",
      html: `
      <table  class="table table-fixed" border="1">
      
        <tbody>
          <tr>
            <td>Project Name :</td>
            <td>${id.projectname}</td>
          </tr>
        <tr>
        <td>Client Name :</td>
        <td>${id.clientname}</td>
        </tr>
        <tr>
        <td>Manager Name :</td>
        <td>${id.managername}</td>
        </tr>
        <tr>
        <td>Status :</td>
        <td>${id.status}</td>
        </tr>
        <tr>
        <td>Modules :</td>
        <td>${id.modules}</td>
        </tr>
        <tr>
        <td>Milestone :</td>
        <td>${id.milestone}</td>
        </tr>
        </tbody>
      </table>
    `,
      confirmButtonText: "Close",
    });
  };

  const validateValues = z.object({
    projectname: z
      .string()
      .min(1, { message: "Project Name feild is required !" }),
    clientname: z
      .string()
      .min(1, { message: "Client Name feild is required !" }),
    managername: z.string().min(1, { message: "Manager Name is required !" }),
    // team: z.string(),
    // .min(1, { message: "Team is required !" }),
    projectcategory: z.string().array().min(1),
    // .min(0,{message:"projectcategory is required !"}),
    modules: z.string().min(1, { message: "Modules is required !" }),
    milestone: z.string().min(1, { message: "Milestone is required !" }),
    status: z.string().optional(),
    // .nullish({message:"Status is required !"}),
    // .min(1,{message:"status is required !"}),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validateValues),
  });

  const onSubmitForm = async (data) => {
    //console.log("inside clicked", data)
    try {
      let token = localStorage.getItem("authToken");
      //console.log("inside clicked", data)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsLoading(true);
      axios
        .post(`${BACKEND_URL}/user/project`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          //console.log(response,"-=-=-=-=-=--=response")
          toast.success("Project created successfully");
          history.push("/admin/contact-table");
          reset();
        })
        .catch((error) => {
          //console.log(error);
          toast.error("Details does not send");
        });
    } catch (error) {
      //console.log(error)
      toast.error("Details does not got");
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    reset();
  };


  return (
    <>
    {verifyAdmin == "admin" ? (

      <div className="contactContainer">
      {/* {verifyAdmin == "admin" ? ( */}
      <div className="createbtncss">
        <Button onClick={createContact} class="btn btn-primary">
          Create
        </Button>
      </div>
      {/* ) : "" } */}
      <div>
        <table class="table table-fixed ">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Client Name</th>
              <th>Manager Name</th>
              <th>Team</th>
              {/* <th>Status</th> */}
              <th>Project Category</th>
              {/* <th>Modules</th>
                          <th>Milestone</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="11">
                  <div className="text-center p-3 my-5">
                    <i class="fa-solid fa-spinner fa-spin loaderspin"></i>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {isContact.length > 0 ? (
                  <>
                    {isContact.map((contact) => (
                      <tr>
                        <td className="justify-content-center">
                          {contact.projectname}
                        </td>
                        <td>{contact.clientname}</td>
                        <td>{contact.managername}</td>
                        <td>
                          {contact && contact.teamMembers.length > 0 && contact.teamMembers.map((item) => (
                            <ul className="licss">
                              <li>{item}</li>
                            </ul>
                          ))}
                        </td>
                        {/* <td>{contact.status}</td> */}

                        <td>
                          {contact.projectcategory.map((name) => (
                            <ul className="licss">
                              <li>{name}</li>
                            </ul>
                          ))}
                        </td>
                        {/* <td>{contact.modules}</td>
                          <td>{contact.milestone}</td> */}
                        <td>
                          <div className="d-flex justify-content-center">
                            <Link to={`/editproject/${contact._id}`}>
                              <i class=" pencss text-primary fa-solid fa-pen"></i>{" "}
                            </Link>
                            <i
                              class=" dltcss text-danger fa-solid fa-trash"
                              onClick={() => deleteContact(contact._id)}
                            ></i>
                            <i
                              class="fa-regular fa-eye dltcss text-dark"
                              onClick={() => viewdata(contact)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="11">
                        <div className="text-center align-items-center justify-content-center p-3 my-5">
                          {/* there is no product  */}

                          <p className="datacss">No Datas Found !</p>
                          <p className="my-3">
                            There is no datas to show you now
                          </p>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <div className="paginationcss">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"***"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-md justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
      </div>
      </div>
    ) : (

      <div className="container">
         <form className="form">
        <div className="contacthead text-center mb-3">
          <h1>Create Project</h1>
        </div>
        <div className="form-control">
          <label>Project Name</label>
          <input
            type="text"
            name="projectname"
            placeholder="Enter Project name"
            {...register("projectname")}
          />
          {errors?.projectname && (
            <p className="errorcss">{errors.projectname?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Client Name</label>
          <input
            type="text"
            name="clientname"
            placeholder="Enter Client Name"
            {...register("clientname")}
          />
          {errors?.clientname && (
            <p className="errorcss">{errors.clientname?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Manager Name</label>
          <input
            type="text"
            name="managername"
            placeholder="Enter Manager Name"
            {...register("managername")}
          />
          {errors?.managername && (
            <p className="errorcss">{errors.managername?.message}</p>
          )}
        </div>
        {/* <div class="form-control">
          <label>Team</label>
          <input
            type="text"
            name="team"
            placeholder="Enter Team members"
            {...register("team")}
          /> */}
          {/* <div id="emailList"></div> */}
          {/* {errors?.team && <p className="errorcss">{errors.team?.message}</p>}
        </div> */}
        <div class="form-control">
          <label>Project Category</label>
          <div className="row mt-3">
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  value="website"
                  name="projectcategory"
                  {...register("projectcategory")}
                />
              </div>
              <div>
                <label className="mx-3">Website</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  value="mobileapp"
                  name="projectcategory"
                  {...register("projectcategory")}
                />
              </div>
              <div>
                <label className="mx-3"> Mobile app</label>
              </div>
            </div>
          </div>
          {errors?.projectcategory && (
            <p className="errorcss">Project Category is required !</p>
          )}
        </div>

        <div class="form-control">
          <label>Status</label>
          <div className="row mt-3">
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="radio"
                  name="status"
                  value="ongoing"
                  {...register("status")}
                />
              </div>
              <div>
                <label className="mx-3"> Ongoing</label>
              </div>
            </div>
            <div className="col-lg-6 px-4 py-2 d-flex">
              <div>
                <input
                  type="radio"
                  name="status"
                  value="completed"
                  {...register("status")}
                />
              </div>
              <div>
                <label className="mx-3"> Completed</label>
              </div>
            </div>
          </div>
          {errors?.status && <p className="errorcss">Status is Required !</p>}
        </div>

        <div class="form-control">
          <label>Modules</label>
          <textarea
            rows={2}
            name="modules"
            placeholder="Enter Modules"
            {...register("modules")}
          />
          {errors?.modules && (
            <p className="errorcss">{errors.modules?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Milestone</label>
          <textarea
            rows={2}
            name="milestone"
            placeholder="Enter Milestone"
            {...register("milestone")}
          />
          {errors?.milestone && (
            <p className="errorcss">{errors.milestone?.message}</p>
          )}
        </div>

        {!isLoading && (
          <>
            <div className="row justify-content-center mb-3">
              <div className="col-lg-3 my-2 mx-3">
                <Button onClick={onCancel}>Cancel</Button>
              </div>
              <div className="col-lg-3 my-2 mx-3">
                <Button type="submit" onClick={handleSubmit(onSubmitForm)}>
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
        </form>
      </div>

    )}
    </>
    
  );
}

export default ProjectTable;
