import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { env } from "../../../envconfig";
import { Icon,Input,
  InputGroup,
  InputRightElement} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function EditAssignee() {
  const BACKEND_URL = env.BACKEND_URL;

  let { id } = useParams();
  const [product, setProduct] = useState({
    name: "",
    email: "",
    roletype: ""
  });
  const [isLoading, setIsLoading] = useState("");

  const history = useHistory();

  const textColorSecondary = "gray.400";

  useEffect(() => {
    getAssignee();
  }, []);

  const getAssignee = async () => {
    try {
      let token = localStorage.getItem("authToken");
      setIsLoading(true);
      const response = await axios.get(
        `${BACKEND_URL}/user/getEditassignee/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProduct({
        name: response.data.details.fullName,
        email: response.data.details.email,
        roletype: response.data.details.userType
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong try again");
    }
  };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const onSubmit = async () => {
    try {
      if (
        product.name === "" ||
        product.email === "" ||
        product.roletype === ""
      ) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        toast.error("Fill out the all feilds");
      } else {
        let token = localStorage.getItem("authToken");
        setIsLoading(true);
        axios
          .put(`${BACKEND_URL}/user/updateAssignee/${id}`, product, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            toast.success("Details updated successfully");
            history.push("/admin/assignee-table");
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } catch (error) {
      // console.log(error.message)
      toast.error("Details don't got");
      setIsLoading(false);
    }
  };

  const cancelFn = () => {
    history.push("/admin/assignee-table");
  };

  return (
    <div className="container">
      <form className="form">
        <div className="cancelcss">
          <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
        </div>
        <div className="contacthead text-center mb-3">
          <h1>Edit Assignee</h1>
        </div>
        <div className="form-control">
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter name"
            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
          />
        </div>
        <div class="form-control">
          <label>Email</label>
          <input
            disabled
            type="email"
            name="email"
            placeholder="Enter email"
            value={product.email}
            onChange={(e) => setProduct({ ...product, email: e.target.value })}
          />
        </div>
        {/* <div class="form-control">
          <label>Password</label>
          <input
            disabled
            type={show ? "text" : "password"}
            name="email"
            placeholder="Enter email"
            value={product.email}
            onChange={(e) => setProduct({ ...product, email: e.target.value })}
          />
        </div> */}
        {/* <div class="form-control">
          <label>Password</label>
          <InputGroup size="md">
            <Input
              isRequired={true}
              fontSize="sm"
              disabled
              name="password"
              value={product.password}
              onChange={(e) => setProduct({ ...product, email: e.target.value })}
              size="lg"
              type={show ? "text" : "password"}
              variant="auth"
            />
            <InputRightElement display="flex" alignItems="center" mt="8px">
              <Icon
                color={textColorSecondary}
                _hover={{ cursor: "pointer" }}
                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
        </div> */}
        <div class="form-control">
          <label>Role type</label>
          <select
            name="roletype"
            value={product.roletype}
            onChange={(e) =>
              setProduct({ ...product, roletype: e.target.value })
            }
          >
            <option> Business Development Executive</option>
            <option> Business Analyst </option>
            <option> Business Head </option>
            <option> CPO</option>
            <option> PC</option>
            <option> Digital Marketing </option>
            <option> Team Lead </option>
            <option> Junior </option>
          </select>
        </div>
        {!isLoading && (
          <Button type="submit" onClick={onSubmit}>
            Update
          </Button>
        )}
      </form>
    </div>
  );
}

export default EditAssignee;
