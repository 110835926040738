import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import useStateRef from "react-usestateref";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { env } from "../envconfig";
import { jwtDecode } from "jwt-decode";

function ContactForm() {
  const BACKEND_URL = env.BACKEND_URL;

  const [isLoading, setIsLoading] = useState("");
  const [verifyAdmin, setverifyAdmin] = useState("");

  const history = useHistory();

  const [isCaptchaSuccessful, setIsCaptchaSuccess, isCaptchaSuccessfulref] =
    useStateRef(false);
  const [captchvalue, setcaptchvalue, captchvalueref] = useStateRef("");

  const [isSelectItemFull, setIsSelectItemFull] = useState("");

  const [isNames, setName] = useState([]);

  const [isStatus, setIsStatus,isStatusref] = useStateRef("");
  const [statuserror, setStatuserror,statuserrorref] = useStateRef("");

  useEffect(() => {
    getdetails();
  }, []);

  const getdetails = () => {
    let decoded;
    let token = localStorage.getItem("authToken");
    if (token) {
      decoded = jwtDecode(token);
    }
    setverifyAdmin(decoded.userType);
    axios
      .get(`${BACKEND_URL}/user/assignee`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let data = response?.data.Names.map((name) => {
          return { fullName: name.fullName };
        });

        setName(data);
      })
      .catch((error) => {
        toast.error("Something went wrong ");
        // console.log(error);
      });
  };

  const cancelFn = () => {
    history.push("/admin/contact-table");
  };

  const onChange = (value) => {
    var captcha = value;
    if (captcha) {
      setcaptchvalue(captcha);
    } else {
      setcaptchvalue("");
    }
    // console.log("captcha value:", value);
  };

  const validateValues = z.object({
    name: z.string().min(1, { message: "Name feild is required !" }),
    contact: z.string().min(1, { message: "Contact feild is required !" }),
    altercontact:z.string(),
    channel: z.string().min(1, { message: "Channel is required !" }),
    country: z.string().min(1, { message: "Country is required !" }),
    date: z.string().min(1, { message: "Date is required !" }),
    category: z.string().min(1, { message: "Category is required !" }),
    subcategory: z.string().min(1, { message: "Subcategory is required !" }),
    // status: z.string().min(1, { message: "status is required !" }),
    description: z.string().min(1, { message: "Description is required !" }),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validateValues),
  });

  const onSubmit = async (data) => {
    console.log("inside clicked", data)
    if (captchvalueref.current == "") {
      setIsCaptchaSuccess(true);
    }
     else {
      if (selectItem == "") {
        setIsSelectItemFull(true);
      }else if(isStatusref.current == "") {
        setStatuserror(true);
      }
       else {
        try {
          let token = localStorage.getItem("authToken");
          // console.log("inside clicked", data)
          setIsCaptchaSuccess(false);
          setStatuserror(false);
          setIsSelectItemFull(false);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setIsLoading(true);
          data["assignee"] = selectItem;
          data["status"] = isStatusref.current;
          axios
            .post(`${BACKEND_URL}/user/contact`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              console.log(response,"-=-=-=-=-=--=response");
              toast.success("Contact created successfully");
              history.push("/admin/contact-table");
              reset();
            })
            .catch((error) => {
              // console.log(error);
            });
        } catch (error) {
          // console.log(error)
          toast.error("Details does not got");
          setIsLoading(false);
        }
      }
    }
  };

  const onSubmitAssignee = async (data) => {
    if (captchvalueref.current == "") {
      setIsCaptchaSuccess(true);
    } else {
      try {
        let token = localStorage.getItem("authToken");
        // console.log("inside clicked", data)
        setIsCaptchaSuccess(false);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsLoading(true);
        axios
          .post(`${BACKEND_URL}/user/contact`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response,"-=-=-=-=-=--=response");
            toast.success("Contact created successfully");
            history.push("/admin/contact-table");
            reset();
          })
          .catch((error) => {
            // console.log(error);
          });
      } catch (error) {
        // console.log(error)
        toast.error("Details does not got");
        setIsLoading(false);
      }
    }
  }

  const onCancelAssignee = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsCaptchaSuccess(false);
    reset();
  };


  const onCancel = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsCaptchaSuccess(false);
    setStatuserror(false);
    setIsSelectItemFull(false);
    reset();
    setIsStatus("");
  };

  const [selectItem, setSelectItem] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectItem(selectedList);
    // console.log({selectedList, selectedItem}, "value")
  };

  const onDelete = (selectedList, removedItem) => {
    setSelectItem(selectedList);
    // console.log({selectedList, removedItem},"removed value")
  };

  return (
    <div className="contactformcontainer">
      <form className="form">
        <div className="cancelcss">
          <i class="fa-solid fa-arrow-left fa-xl" onClick={cancelFn}></i>
        </div>
        <div className="contacthead text-center mb-3">
          <h1>Create Contact</h1>
        </div>
        <div className="form-control">
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter name"
            {...register("name")}
          />
          {errors?.name && <p className="errorcss">{errors.name?.message}</p>}
        </div>
        <div class="form-control">
          <label>Contact</label>
          <input
            type="text"
            name="contact"
            placeholder="Enter contact [ Email, Phone, Telegram, Skype ]"
            {...register("contact")}
          />
          {errors?.contact && (
            <p className="errorcss">{errors.contact?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Alternative Contact </label>
          <input
            type="text"
            name="altercontact"
            placeholder="Optional"
            {...register("altercontact")}
          />
        </div>
        <div class="form-control">
          <label>Channel</label>
          <input
            type="text"
            name="channel"
            placeholder="Enter Channel"
            {...register("channel")}
          />
          {errors?.channel && (
            <p className="errorcss">{errors.channel?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Country</label>
          <input
            type="text"
            name="country"
            placeholder="Enter country"
            {...register("country")}
          />
          {errors?.country && (
            <p className="errorcss">{errors.country?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>Date</label>
          <input
            type="date"
            name="date"
            placeholder="Enter date"
            {...register("date")}
          />
          {errors?.date && <p className="errorcss">{errors.date?.message}</p>}
        </div>
        <div class="form-control">
          <label>Category</label>
          <input
            type="text"
            name="category"
            placeholder="Enter Category"
            {...register("category")}
          />
          {errors?.category && (
            <p className="errorcss">{errors.category?.message}</p>
          )}
        </div>
        <div class="form-control">
          <label>SubCategory</label>
          <input
            type="text"
            name="subcategory"
            placeholder="Enter SubCategory"
            {...register("subcategory")}
          />
          {errors?.subcategory && (
            <p className="errorcss">{errors.subcategory?.message}</p>
          )}
        </div>
        {verifyAdmin == "admin" ? (
        <>
          <div class="form-control">
          <label>Assignee </label>

          <Multiselect
            options={isNames}
            displayValue="fullName"
            className="mt-3"
            value="fullName"
            name="assignee"
            onSelect={onSelect}
            onRemove={onDelete}
          />

          {isSelectItemFull == true ? (
            <p className="errorcss">
              <span> Assignee is required !</span>
            </p>
          ) : (
            ""
          )}
        </div>
        <div class="form-control">
          <label>Status</label>
          {/* <input type="text" name="roletype" placeholder="Enter role type" /> */}
          <select
            name="status"
            placeholder="Enter status"
            // {...register("status")}
            onChange={(e) => {
              setIsStatus(e.target.value);
            }}
          >
            {isStatusref.current == "" ? (
              <>
               <option value="" selected disabled>-- Select an option --</option>
              <option> New </option>
              <option> Ongoing </option>
              <option> Converted</option>
              <option> Rejected</option>
              </>
            ):(
              <>
               <option>{isStatusref.current}</option>
              <option> New </option>
              <option> Ongoing </option>
              <option> Converted</option>
              <option> Rejected</option>
              </>
            )}
           
          </select>

          {/* {errors?.status && (
            <p className="errorcss">{errors.status?.message}</p>
          )} */}
          {statuserrorref.current == true ? (
            <p className="errorcss">Status is required !</p>
          ) : (
            ""
          )}
        </div>
        </>

        ):(
          ""
        )}
       

        <div class="form-control">
          <label>Description</label>
          <input
            type="text"
            name="description"
            placeholder="Enter description"
            {...register("description")}
          />
          {errors?.description && (
            <p className="errorcss">{errors.description?.message}</p>
          )}
        </div>
        <div class="form-group">
          <ReCAPTCHA
            sitekey={env.RECAPTCHA_SITE_KEY}
            onChange={onChange}
          />
        </div>
        {isCaptchaSuccessfulref.current == true ? (
          <p className="errorcss">
            <span> Accept terms and condition !</span>
          </p>
        ) : (
          ""
        )}

        {!isLoading && (
          <>
           
             
              {verifyAdmin == "admin" ? (
                <>
                 <div className="row justify-content-center mb-3">
                 <div className="col-lg-2 my-2 mx-3">
                <Button onClick={onCancel}>Cancel</Button>
               </div>
                <div className="col-lg-2 my-2 mx-3">
                <Button type="submit" onClick={handleSubmit(onSubmit)}>
                  Submit
                </Button>
              </div>
              </div>
                </>
              ) : (
                <>
                <div className="row justify-content-center mb-3">
                 <div className="col-lg-2 my-2 mx-3">
                <Button onClick={onCancelAssignee}>Cancel</Button>
               </div>
                <div className="col-lg-2 my-2 mx-3">
                <Button type="submit" onClick={handleSubmit(onSubmitAssignee)}>
                  Submit
                </Button>
              </div>
              </div>
                </>
              )}
              
          </>
        )}
      </form>
    </div>
  );
}

export default ContactForm;
